import React from 'react';
import Fixture from '../fixture/fixture';

import "./list-fixtures.scss";

export default class ListFixtures extends React.Component {
    
    fixturesList = null;
    loadedFixtures = false;

    getFitViewBox(fixture, keepAspectRatio=false, padding=0) {
        const x = fixture.bounds.xMin;
        const y = fixture.bounds.yMin;

        const {width, height} = fixture.bounds.size();

        // Maintain aspect ratio of the viewbox if specified.  Use
        // longest dimension.
        const w = keepAspectRatio === true ? Math.max(width, height) : width;
        const h = keepAspectRatio === true ? Math.max(width, height) : height;

        return {x: x - padding, y: y - padding, w: w + (padding*2), h: h + (padding*2) };
    }

    getFitViewBoxString(fixture) {
        const {x, y, w, h} = this.getFitViewBox(fixture,false, 10);

        return `${x},${y},${w},${h}`;
    }

    isSelected(fixture, selectedFixture) {
        if(selectedFixture === undefined)
            return false;

        return selectedFixture === fixture;
    }

    render() {
        const {fixtures, selectedFixture, onSelectFixture} = this.props;

        const clickEvent = (fixture) => { 
            console.log(fixture);
            onSelectFixture(fixture);
        }

        return (
            <div>
                <h3>Fixtures</h3>
                <div className="fixtures-container">
                {
                    fixtures.map((f, i) => {
                        return (
                            <span className="standardFixture">{f.fixtureData.DESCRIPTION + ":" + f.fixtureData.WIDTH + "x" + f.fixtureData.DEPTH + "x" + f.fixtureData.HEIGHT}<br></br>
                            <svg key={i}
                                className={this.isSelected(f, selectedFixture) ? "selected-svg" : "unselected-svg"}
                                viewBox={this.getFitViewBoxString(f)}
                                width="100" 
                                height="100"
                            >
                                <Fixture fixture={f} onClick={(event) => {clickEvent(f)}} rackColor="FFFFFF"></Fixture>
                                
                            </svg><br></br>
                            </span>
                        )
                    })
                }
                </div>
            </div>
        );
    }
}