import React from 'react';
import anime from 'animejs';
import chroma from 'chroma-js';
import "./fixture.scss";

export default class Fixture extends React.Component {

    // Props: fixture, rackColor, lineColor, fixtureIndex
    svgRectElem = null;
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    hasPointGeometry() {
        return this.props.fixture.hasPointGeometry();
    }

    hasDimensions() {
        return this.props.fixture.hasDimensions();
    }

    // Renders points if they are specified, a rectangle if
    // width/height are available, nothing otherwise

    render() {

        const {onClick, onEnter, onLeave} = this.props;

        const clickEvent = (event) => { 
            this.props.onClick(this.props.fixture);
            event.stopPropagation();
        }
        const enterEvent = (event) => { 
            this.props.onEnter(this.props.fixture);
            event.stopPropagation();
        }
        const leaveEvent = (event) => {
            this.props.onLeave(this.props.fixture);
            event.stopPropagation();
        }
        const callbacks = {
            onClick: onClick ? clickEvent : null,
            onTouchEnd: onClick ? clickEvent : null,
            onMouseEnter: onEnter ? enterEvent : null,
            onMouseLeave: onLeave ? leaveEvent : null,
        }

        if(this.hasPointGeometry()) {
            return this.props.fixture.getPolyString().map((points, index) => (
                <polygon ref={(e) => this.svgRectElem = e}
                    key={index}
                    className={"fixture"}
                    id={this.props.fixture.fixtureData.RACK_ID}
                    points={points} 
                    style={{
                        fill:"#"+this.props.rackColor,
                        stroke:this.props.lineColor,
                        strokeWidth:2 
                    }}
                    {...callbacks}
                >
                </polygon>
            ));
        } else if(this.hasDimensions()) {
            return (
                <rect width={this.props.fixture.fixtureData.WIDTH} height={this.props.fixture.fixtureData.DEPTH}
                className={"fixture"}
                id={this.props.fixture.fixtureData.RACK_ID}
                style={{
                    fill:"#"+this.props.rackColor,
                    stroke:this.props.lineColor,
                    strokeWidth:2 
                }}
                {...callbacks}
                ></rect>
            );
        } else {
            return (
                <rect width="50" height="50"
                className={"fixture"}
                id={this.props.fixture.fixtureData.RACK_ID}
                style={{
                    fill:"#FF0000",
                    stroke:this.props.lineColor,
                    strokeWidth:2 
                }}
                {...callbacks}
                ></rect>
            );
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.selected !== this.props.selected || this.props.fixture !== nextProps.fixture;
    }

    componentDidUpdate() {
        try {

            if(this.props.selected || /multi/.test(this.props.currentAction) === true)
            {
                anime({
                    targets: document.getElementById(this.props.fixture.fixtureData.RACK_ID),
                    strokeWidth: 4,
                    stroke: "#0000ff",
                    fill: chroma.mix(this.props.rackColor, "00ff00", 0.75).hex(),
                    baseFrequency: 0,
                    loop: false,
                    duration: 1000,
                    direction: 'normal',
                    easing: 'spring'
                });
            } else {
                anime({
                    targets: document.getElementById(this.props.fixture.fixtureData.RACK_ID),
                    strokeWidth: 2,
                    stroke: chroma(this.props.lineColor).hex(),
                    fill: "#"+this.props.rackColor,
                    baseFrequency: 0,
                    loop: false,
                    duration: 1000,
                    direction: 'normal',
                    easing: 'spring'
                });
            }
        } catch(err) {
            console.log(`Couldn't update fixture id ${this.props.fixture.fixtureData.RACK_ID}: `, err);

            try {
                anime({
                    targets: document.getElementById(this.props.fixture.fixtureData.RACK_ID),
                    strokeWidth: 4,
                    stroke: "#ffff00",
                    fill: "#ff0000",  // RED color for warning
                    baseFrequency: 0,
                    loop: false,
                    duration: 1000,
                    direction: 'normal',
                    easing: 'spring'
                });
            } catch(fallbackErr) {
                console.log(`Couldn't fallback to default color for fixture`);
            }
        }
    }
}