import Bounds from '../util/bounds.js';

class LabelGroup
{
    constructor(label, fixtures)
    {
        this.label = label;
        this.fixtures = fixtures;
    }

    getBounds() {
        let result = new Bounds();
        this.fixtures.forEach((f) => { 
            
            if(f.bounds)
            {
                result = result.expand(f.bounds);
            }
            
        });

        return result;
    }

    draw(canvas, drawInfo) {

        const lgBounds = this.getBounds();
            
        if(lgBounds)
        {
            const size = lgBounds.size();
            const pad = 10;
            const halfPad = pad / 2;

            const tallerThanWide = lgBounds.isTallerThanWide();

            // Draw label group bounds
            if(drawInfo.debug)
                canvas.rect((size.width + pad), (size.height + pad)).fill('blue').move((lgBounds.xMin - halfPad), (lgBounds.yMin - halfPad)).opacity(0.25);
        
            if(this.label) {

                const labelText = `${this.label}`;

                if(tallerThanWide) {

                    const xText = lgBounds.xMin;
                    const yText = lgBounds.yMax;

                    const minSize = 10;
                    const maxSize = 60;
                    const textLength = this.label.length;
                    const containerSize = size.height.clamp(0, 400);
                    const ratio = (containerSize / 10) / (textLength * 4);
                    const fontSize = (maxSize * ratio).clamp(minSize, maxSize);

                    // Draw label vertically
                    canvas.text(function(add) {
                        add.tspan(labelText);
                    }).font({
                        family:   'Arial Narrow'
                        , size:     fontSize
                        , fill:     '#000000'
                        , anchor:   'start'
                        , textlength: size.height
                        , lengthAdjust: 'spacing'
                    }).move(xText, (yText)-(fontSize / 3)).rotate(-90, xText, yText);
                    
                } else {

                    const xText = lgBounds.xMin;
                    const yText = lgBounds.yMin;

                    const minSize = 10;
                    const maxSize = 60;
                    const textLength = this.label.length;
                    const containerSize = size.width.clamp(0, 400);
                    const ratio = (containerSize / 10) / (textLength * 4);
                    const fontSize = (maxSize * ratio).clamp(minSize, maxSize);

                    // Draw label horizontally
                    canvas.text(function(add) {
                        add.tspan(labelText);
                    }).font({
                        family:   'Arial Narrow'
                        , size:     fontSize
                        , fill:     '#000000'
                        , anchor:   'start'
                    }).move(xText, (yText)-(fontSize / 3));
                }
            }
        }
    }
}

export default LabelGroup;