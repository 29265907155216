import React from 'react';
import RackInfo from './rack-info/rack-info';
import ListFixtures from '../list-fixtures/list-fixtures';
import SVGMap from './svg-map';
import SVGMapUtils from "../../lib/smartmap/util/map-utils";
import {AutoSizer} from 'react-virtualized';
import {ReactSVGPanZoom, TOOL_AUTO, TOOL_NONE} from 'react-svg-pan-zoom';
import Hammer from 'hammerjs';
import SmartMapService from "../../lib/smartmap/smart-map-service";
import anime from 'animejs';
import chroma from 'chroma-js';
import FitToScreenToolbar from "./fit-to-screen-toolbar";

import "./svg-map.scss";
import "./svg-map-spinner.scss";
import moment from 'moment';
import StoreMapProcessor from '../../lib/smartmap/store-map-processor';
import Bounds from '../../lib/smartmap/util/bounds';
const inRange = (start, end) => n => {
    if (start < end) {
        return n >= start && n <= end;
    } else {
        return n >= end && n <= start;
    }
};
export default class SVGMapContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalUrl: this.props.defaultModalUrl + this.props.inputParamsString,
            modalChild: undefined,
            modalOpen: true,
            fixturePaletteSelection: undefined,
            selectedFixture: undefined,
            hoveredFixture: undefined,
            mousePos: {x: 0, y: 0},
            renderFast: false,
            scale: undefined,
            storeData: undefined,
            storeExtents: undefined,
            panX: 0,
            panY: 0,
            zoom: 1,
            value: {},
            tool: TOOL_AUTO,
            currentAction: undefined,
            snapRadius: 0,
            mouseClickPosOnFix: {x: 0, y: 0},
            error: {error: false, message: ""},
            selectedFixturesArray: [],
            drawMode: false,
            drawStart: null,
            drawEnd: null,
            drawSelect: null,
            tooltip: true,
            preSelectedFixId:0
        };
        this.viewer = null;
        this.changeValue = this.changeValue.bind(this);

        this.enterFixture = this.enterFixture.bind(this);
        this.leaveFixture = this.leaveFixture.bind(this);
        this.clickFixture = this.clickFixture.bind(this);
        this.clickNothing = this.clickNothing.bind(this);
        this.clickModalClose = this.clickModalClose.bind(this);
        this.clickModalRefresh = this.clickModalRefresh.bind(this);
        this.clickMarker = this.clickMarker.bind(this);

        this.mouseMove = this.mouseMove.bind(this);

        this.handleWheel = this.handleWheel.bind(this);
        this.startDrawing = this.startDrawing.bind(this);
        this.endDrawing = this.endDrawing.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this)
        // Pan gesture
        this.handleTouchPanStart = this.handleTouchPanStart.bind(this);
        this.handleTouchPanMove = this.handleTouchPanMove.bind(this);
        this.handleTouchPanEnd = this.handleTouchPanEnd.bind(this);

        // Pinch zoom gesture
        this.handleTouchPinchStart = this.handleTouchPinchStart.bind(this);
        this.handleTouchPinchMove = this.handleTouchPinchMove.bind(this);
        this.handleTouchPinchEnd = this.handleTouchPinchEnd.bind(this);

        // Disable clicks temporarily after panning to avoid ghost click issue
        this.disableClicks = false;

        this.smartMap = new SmartMapService();
        this.smartMapUtils = null;
        this.insertFixtureParams = {fixtureGeo: undefined, fixture_description: undefined, rack_type_id: 0, resizable: 0, skip_process: 0}
        this.mapClickPnt = {x: 0, y: 0}
        // Should allow cross-origin function calls
        window.addEventListener('message', event => {
            try{
                if (
                    event.origin.startsWith('https://app3.retailgis.com') ||
                    event.origin.startsWith('https://storemap.retailgis.com') ||
                    event.origin.startsWith('http://storemap.retailgis.com:3000') ||
                    event.origin.startsWith('http://localhost:3000')
                    ) {

                    console.log('Recieved an event:', event.data);

                    this[event.data.function](event.data.param);
                } else {
                    console.log('Bad script call origin');
                }
            } catch(err) {
                console.log(err);
            }

            return Promise.resolve("Message received");
        });
    }

    // Exposed functions
    hideAndShowTooltip() {
        this.setState(() => ({
            tooltip: !this.state.tooltip
        }));
    }
    splitFixture(splitCnt) {
        if (this.state.selectedFixture) {
            this.setState(() => ({
                currentAction: "splitFixture"
            }));
            this.splitFixtureEvent(splitCnt);
        }
    }
    switchDescription(desc) {
        this.switchDescriptionEvent(desc);
    }    
    mergeFixture() {
        this.setState(() => ({
            currentAction: "mergeFixture"
        }));
    }
    selectFixtureById(id) {
        try {
            const selectFixture = this.state.storeData.fixtures.find(f => f.fixtureData.RACK_ID === id);
            if(selectFixture === undefined) {
                console.log(`Please check ..fixture:${id} is not found on SmartMap`);
                return;
            }
            this.clickFixture(selectFixture);
            const bounds = selectFixture.bounds;
            const totalBounds = this.state.storeData.totalBounds;
            const xTransform = -1 * totalBounds.xMin;
            const yTransform = -1 * totalBounds.yMin;

            const paddedBounds = bounds.pad(window.innerWidth * 0.5, window.innerHeight * 0.5);
            const { width, height } =  paddedBounds.size();

            this.viewer.fitSelection(paddedBounds.xMin + xTransform, paddedBounds.yMin + yTransform, width, height);
        } catch(error) {
            console.log(`Failed to select fixture`, error);
        }
    }
    focusFixtures(fixtures) {
        try {
            if(!Array.isArray(fixtures)) return;
            const selected = [...fixtures];
            const bounds = selected.reduce((acc, curr) => {return curr.bounds.expand(acc)}, selected[0].bounds);
            const totalBounds = this.state.storeData.totalBounds;
            const xTransform = -1 * totalBounds.xMin;
            const yTransform = -1 * totalBounds.yMin;

            const paddedBounds = bounds.pad(window.innerWidth * 0.5, window.innerHeight * 0.5);
            const { width, height } =  paddedBounds.size();

            this.viewer.fitSelection(paddedBounds.xMin + xTransform , paddedBounds.yMin + yTransform, width, height);
        } catch(error) {
            console.log(`Failed to focus fixtures`, error);
        }
    }
    refreshFixturesByIds({ids, extraParams}) {
        try {
            this.updateFixtures(ids, extraParams);
        }catch(error){
            console.log(`Failed to refresh fixtures`, error)
        }
    }
    setMapAction(params) {
        try {
            let isDrawModeSet = (params.action === "zoomRect" || params.action === "multiFixture") ? true : false;
            this.setState(() => ({
                tool: isDrawModeSet === true ? TOOL_NONE : TOOL_AUTO,
                drawMode: isDrawModeSet === true ? true : false,
                currentAction: params.action,
                snapRadius: params.snapRadius === undefined ? 18 : params.snapRadius
            }));
        } catch (error) {
            console.log(`Failed to set ${params.action} action`, error)
        }
    }
    async resetMapAction() {
        try {
            if (/multiFixture/i.test(this.state.currentAction) === true || this.state.selectedFixturesArray.length > 0) {
                this.state.selectedFixturesArray.forEach((fix, index) => {
                    anime({
                        targets: document.getElementById(fix.fixtureData.RACK_ID),
                        strokeWidth: 2,
                        stroke: chroma(this.props.inputParams.lineColor).hex(),                    
                        fill: "#"+fix.fixtureData.BAYLOCK_COLOR
                    });
                })
            }
            this.setState(() => ({
                currentAction: undefined,
                selectedFixture: undefined,
                preSelectedFixId:0,
                modalUrl: this.props.defaultModalUrl + this.props.inputParamsString,
                modalChild: undefined,
                tool: TOOL_AUTO,
                drawMode: false,
                selectedFixturesArray: []                              
            }));
            if (document.getElementById("editSpinBox"))
                document.getElementById("editSpinBox").remove();
            let snapLineDrawn = document.getElementById('snapLine');
            if (snapLineDrawn !== null) {
                snapLineDrawn.parentNode.removeChild(snapLineDrawn);
            }
            let snapLineAction = document.getElementById('snapLineAction');
            if (snapLineAction !== null) {
                snapLineAction.parentNode.removeChild(snapLineAction);
            }                 
        } catch (error) {
            console.log(`Failed to reset edit action`, error)
        }
    }
    deleteFixture() {
        try {
            this.setState(() => ({
                currentAction: "deleteFixture"
            }));
            this.deleteSelectedFixtures();
        } catch (error) {
            console.log(`Failed to delete fixture`, error)
        }
    }
    rotateFixture(angle) {
        try {
            this.setState(() => ({
                currentAction: "rotateFixture"
            }));
            this.processRotateFixture(angle);
        }catch(error){
            console.log(`Failed to refresh fixtures`, error)
        }
    }    
    insertFixture(params){
        try {
            this.setState(() => ({
                currentAction: "insertFixture"
            }));
            let jsonObj = JSON.parse(JSON.stringify(params));
            let fixtureGeo = jsonObj.item_geometry
            let fixture_description = jsonObj.item_description
            let rack_type_id = jsonObj.rack_type_id
            let resizable = jsonObj.resizable
            let skip_process = jsonObj.skip_process
            this.insertFixtureParams = {fixtureGeo: fixtureGeo, 
                fixture_description: fixture_description, 
                rack_type_id: rack_type_id, resizable: resizable, skip_process: skip_process} 
        } catch (error) {
            console.log(`Failed to insert fixture`, error)
        }
    } 
    closeInsertFixture() {
        this.setState(() => ({
            modalUrl: this.getRackInfoUrl(this.state.selectedFixture.fixtureData.RACK_ID),
            modalChild: undefined,
            selectedFixture: this.state.selectedFixture,
            modalOpen: true
        }));
    }
    fixturePaletteFixtureSelected(selectedFixture, fixturesList) {

        const fixtureData = selectedFixture.fixtureData;
        this.insertFixtureParams = {
            fixtureGeo: fixtureData.FIXTUREGEOMETRY, 
            fixture_description: fixtureData.DESCRIPTION, 
            rack_type_id: fixtureData.ID, resizable: fixtureData.RESIZEABLE, skip_process: fixtureData.SKIP_PROCESS
        }; 

        this.setState(() => ({
            currentAction: "insertFixture",
            fixturePaletteSelection: selectedFixture,
            modalChild: (<ListFixtures fixtures={fixturesList} selectedFixture={selectedFixture} onSelectFixture={(fixture) => this.fixturePaletteFixtureSelected(fixture, fixturesList)}></ListFixtures>),
        }));
    }
    async renderStandardFixtures() {
        const {apiUrl, inputParams} = this.props;
        const fixturesList = await this.smartMap.fetchFixtureTypes(apiUrl, inputParams);
        this.setState(() => ({
            modalUrl: "",
            modalChild: (<ListFixtures fixtures={fixturesList} selectedFixture={this.state.fixturePaletteSelection} onSelectFixture={(fixture) => this.fixturePaletteFixtureSelected(fixture, fixturesList)}></ListFixtures>),
            selectedFixture: undefined,
            modalOpen: true
        }));
    }
    refreshSmartMap() {
        try {
            this.refreshMap();
        } catch (error) {
            console.log(`Failed to reset edit action`, error)
        }
    }
    multiDeleteFixture() {
        try {
            this.setState(() => ({
                currentAction: "multiDeleteFixture"
            }));
            this.deleteSelectedFixtures();
        } catch (error) {
            console.log(`Failed to Multi Delete fixtures`, error)
        }
    }
    multiRotateFixtures(angle) {
        try {
            this.setState(() => ({
                currentAction: "multiRotateFixture"
            }));
            this.processRotateFixture(angle);
        } catch (error) {
            console.log(`Failed to Multi Rotate fixtures`, error)
        }
    }    
    getMapClickPoint(){
        let mapClickPnt = this.smartMapUtils.getSvgPoint(this.mapClickPnt.x, this.mapClickPnt.y);
        console.log("mapClickPnt.x+","+mapClickPnt.y",mapClickPnt.x+","+mapClickPnt.y);
        document.getElementById('taskIframe').contentWindow.postMessage({function: 'getMapClickPoint', param: {mapClickPoint:mapClickPnt.x+","+mapClickPnt.y}}, '*');
        //document.querySelector('#taskIframe').contentWindow.postMessage(mapClickPnt.x+","+mapClickPnt.y, "*");
        //return mapClickPnt.x+","+mapClickPnt.y
    }
    
    // End exposed functions

    mouseMove(event) {
        //console.log("mouseMove----- ",event.type,event);
        if(event.type === "touchmove"){
            this.setState(() => ({
                mousePos: {x: event.touches[0].pageX, y: event.touches[0].pageY}
            }));
        } else {
            this.setState(() => ({
                mousePos: {x: event.pageX, y: event.pageY}
            }));
        }
    }
    /*touchMove(event) {
        console.log("touchMove----- ",event,event.touches[0].pageX,event.touches[0].pageY);
        this.setState(() => ({
            mousePos: {x: event.touches[0].pageX, y: event.touches[0].pageY}
        }));
    }*/
    getRackInfoUrl(rackId) {
        //Moved parameter app_name to renderRackInfo method.

        const fullURL = `${this.props.rackInfoUrl}`+
        `?hdnstoreno=${this.props.inputParams.store}` +
        `&hdnrackid=${rackId}` +
        `&division_id_no=${this.props.inputParams.divisionId}` +
        `&retailer_id_no=${this.props.inputParams.retailerId}` +
        `&layers=${this.props.inputParams.layers}` +
        `&user=${this.props.inputParams.user}`; //+
        //`&app_name=${"react_app"}`;

        return fullURL;
    }
    getMultiRackInfoUrl() {
        let fullURL = `${this.props.multiRackInfoUrl}`+
        `?hdnstoreno=${this.props.inputParams.store}` +
        `&hdnDivisionId=${this.props.inputParams.divisionId}` +
        `&hdnCustomerId=${this.props.inputParams.retailerId}` +
        `&selectedLayer=${this.props.inputParams.layers}` +
        `&count=${this.state.selectedFixturesArray.length}` +
        `&user=${this.props.inputParams.user}`+
        `&cameraFlag=false` +
        `&initialPageFlag=false`;
                
        //let formData = new FormData();
        let fixIdsArray = [];
        this.state.selectedFixturesArray.forEach((fix, index) => {
            if (fixIdsArray.includes(fix.fixtureData.RACK_ID) === false) {
                fixIdsArray.push(fix.fixtureData.RACK_ID)
                if(index === 0)
                    fullURL = fullURL + `&rack_id=${fix.fixtureData.RACK_ID}` //formData.append('rack_id', fix.fixtureData.RACK_ID);
                else
                    fullURL = fullURL + `&rack_id_${index+1}=${fix.fixtureData.RACK_ID}` //formData.append('rack_id_'+(index+1), fix.fixtureData.RACK_ID);     
            }
        })
        /* formData.append('count', this.state.selectedFixturesArray.size);
        formData.append('selectedLayer', this.props.inputParams.layers);
        formData.append('cameraFlag', false);
        formData.append('initialPageFlag', false);
        formData.append('hdnStoreNo', this.props.inputParams.store);
        formData.append('hdnDivisionId', this.props.inputParams.divisionId);
        formData.append('hdnCustomerId', this.props.inputParams.retailerId); */
        console.log("getMultiRackInfoUrl fullURL",fullURL);
        return fullURL;
    }
    getMarkerInfoUrl(markerId) {
        const fullURL = `${this.props.markerInfoUrl}`+
        `?store_no=${this.props.inputParams.store}` +
        `&marker_id=${markerId}` +
        `&division_id_no=${this.props.inputParams.divisionId}` +
        `&retailer_id_no=${this.props.inputParams.retailerId}` +
        `&layers=${this.props.inputParams.layers}` +
        `&user=${this.props.inputParams.user}`;
        console.log("getMarkerInfoUrl fullURL",fullURL);
        return fullURL;
    }    
    enterFixture(fixture) {
        this.setState(() => ({
            hoveredFixture: fixture,
        }));
    }

    leaveFixture(fixture) {
        this.setState(() => ({
            hoveredFixture: undefined,
        }));
    }
    clickMarker(marker) {
        //console.log("marker",marker,marker.ID);
        this.setState(() => ({
            modalUrl: this.getMarkerInfoUrl(marker.ID),
            modalChild: undefined,
            selectedFixture: undefined,
            modalOpen: true
        }));        
    }
    clickFixture(fixture) {
        if(this.disableClicks === true) return;
        if(fixture === undefined) return;
        //this.state.mouseClickPosOnFix = {x: this.state.mousePos.x, y: this.state.mousePos.y}
        this.setState(() => ({
            mouseClickPosOnFix: {x: this.state.mousePos.x, y: this.state.mousePos.y},
            tool: TOOL_NONE
        }));
        if (this.state.currentAction !== "splitFixture") {
            this.setState(() => ({
                preSelectedFixId: fixture.fixtureData.RACK_ID
            }));
        }
        //console.log("Click Event On Fixture",this.state.mousePos,this.state.mouseClickPosOnFix);
        if(/multi/.test(this.state.currentAction) === true){
            this.state.selectedFixturesArray.push(fixture)
            this.setState(() => ({
                modalUrl: this.getMultiRackInfoUrl(),
                modalChild: undefined,
                selectedFixture: fixture,
                modalOpen: true
            })); 
        } else {
            this.setState(() => ({
                modalUrl: this.getRackInfoUrl(fixture.fixtureData.RACK_ID),
                modalChild: undefined,
                selectedFixture: fixture,
                modalOpen: true
            }));
            //console.log("getPointGeometry",fixture);
        }
        this.mapClickPnt = {x: this.state.mousePos.x, y: this.state.mousePos.y}
        //console.log("Click Event On Fixture selectedFixturesArray",this.state.selectedFixturesArray,document.getElementById('taskIframe')); 
        
        if (this.state.preSelectedFixId > 0 && this.state.currentAction === "mergeFixture") {
            this.mergeFixtureEvent(fixture.fixtureData.RACK_ID);
        }
    }

    clickNothing() {
        //console.log('Click location: ', this.smartMapUtils.getSvgPoint(this.state.mousePos.x, this.state.mousePos.y));  // DEBUG - remove me
        if(this.disableClicks === true) return;
        // copyFixture, moveFixture, multiCopyFixture and multiMoveFixture case
        if (/copyFixture/i.test(this.state.currentAction) === true || /moveFixture/i.test(this.state.currentAction) === true) { //(this.state.currentAction === "copyFixture" || this.state.currentAction === "moveFixture") {
            try {
                let destinationPnt;
                let nearRackId = 0;
                let formData = new FormData();
                this.renderMapEditLoading()
                let svg = document.getElementsByTagName('svg')[0];
                let srcRackId = 0;
                let sourcePoint = undefined;
                destinationPnt = this.smartMapUtils.getSvgPoint(this.state.mousePos.x, this.state.mousePos.y);
                destinationPnt = { x: destinationPnt.x, y: -1 * destinationPnt.y };
                if(/multi/.test(this.state.currentAction) === true){
                    /* sourcePoint = this.state.selectedFixturesArray[0].origin;
                    selectedPolygon = svg.getElementById(this.state.selectedFixturesArray[0].fixtureData.RACK_ID); */
                    //let pnt1 =  this.smartMapUtils.getClosestVertex(destinationPnt, selectedPolygon.points);
                    /* var pnt2 =  this.state.selectedFixturesArray[0].origin;
                    pnt1.x = pnt1.x - pnt2.x
                    pnt1.y = pnt1.y - pnt2.y */
                    //destinationPnt = pnt1;//this.smartMapUtils.getSvgPoint(this.state.mousePos.x + Number(pnt1.x), this.state.mousePos.y + Number(pnt1.y));
                    let rtnDetails = this.getSelectedMultiFixtureDetails();
                    srcRackId = rtnDetails.rackIdsCsv;
                    let polygonAccumPntsArray = rtnDetails.polygonAccumPntsArray;
                    sourcePoint = {x:0, y:0}
                    /* sourcePoint = this.smartMapUtils.getBottomLeftVerttex(polygonAccumPntsArray);
                    sourcePoint = {x:sourcePoint.x, y:-1 * sourcePoint.y} */
                    formData.append('list_rack_ids', srcRackId);
                    nearRackId = -10;
                    console.log("container->clickNothing-> polygonAccumPntsArray", polygonAccumPntsArray,sourcePoint);
                } else {
                    let selectedPolygon = svg.getElementById(this.state.selectedFixture.fixtureData.RACK_ID);
                    srcRackId = this.state.selectedFixture.fixtureData.RACK_ID
                    //let sourcePoint = this.getBottomLeftVerttex(selectedPolygon.points);
                    console.log("selectedPolygon.points",selectedPolygon,this.state.selectedFixturesArray[0]);
                    let transformFixClickPt = this.smartMapUtils.getSvgPoint(this.state.mouseClickPosOnFix.x, this.state.mouseClickPosOnFix.y);
                    sourcePoint = this.smartMapUtils.getClosestVertex(transformFixClickPt, selectedPolygon.points);
                    formData.append('copy_rack_id', srcRackId);
                }

                //console.log("container->clickNothing-> getClosestVertex", this.state.mouseClickPosOnFix, selectedPolygon,sourcePoint);
                //console.log("container->clickNothing-> copyMoveFixture", this.state.mousePos.x, this.state.mousePos.y, destinationPnt);
                this.smartMapUtils.getSnapPoint(destinationPnt.x, destinationPnt.y, this.state.snapRadius).then((snapObj => {
                    if (snapObj && snapObj.snapPoint && snapObj.destRACKID !== 0) {
                        //console.log("container->clickNothing-> (snapObj && snapObj.snapPoint && snapObj.destRACKID", snapObj, snapObj.snapPoint, snapObj.destRACKID, sourcePoint, srcRackId);
                        if (snapObj.destRACKID)
                            nearRackId = snapObj.destRACKID;
                        if (snapObj.snapPoint)
                            destinationPnt = snapObj.snapPoint;
                        //console.log("container->clickNothing-> snapObj (destinationPnt)", snapObj);
                    }
                    formData.append('near_rack_id', nearRackId);
                    formData.append('sx', sourcePoint.x);
                    formData.append('sy', sourcePoint.y);
                    formData.append('dx', destinationPnt.x);
                    formData.append('dy', destinationPnt.y);

                    //console.log("container->clickNothing-> formData", srcRackId, nearRackId, sourcePoint.x, sourcePoint.y, transformMPt.x, transformMPt.y);
                    this.fixtureEDITS(formData);
                }));
            } catch (error) {
                this.resetMapAction()
            }
        } else if (this.state.currentAction === "insertFixture" && this.insertFixtureParams.fixtureGeo) {
            try {
                // World space coords of mouse click
                const clickPointWS = this.smartMapUtils.getSvgPoint(this.state.mousePos.x, this.state.mousePos.y);
                console.log('Insertion point: ', clickPointWS);

                this.renderMapEditLoading();
                this.processInsertFixture(this.state.fixturePaletteSelection, clickPointWS);
            } catch (error) {
                console.error(error);
                this.resetMapAction()
            }
        } else if(/multiFixture/i.test(this.state.currentAction) === false) {
            let clkPnt = this.smartMapUtils.getSvgPoint(this.state.mousePos.x, this.state.mousePos.y);
            this.resetMapAction()
            this.setState(() => ({
                modalUrl: this.props.defaultModalUrl + this.props.inputParamsString + "&xy="+clkPnt.x+","+clkPnt.y*-1,
                modalChild: undefined,
                selectedFixture: undefined
            }));
        }
        this.mapClickPnt = {x: this.state.mousePos.x, y: this.state.mousePos.y}
    }
    async processInsertFixture(fixture, insertPoint) {
        try {

            //const geoArray = fixture.getGeometryCenteredToOrigin();
            const geoArray = fixture.getGeometryCenteredToBottomLeft();

            // For each sub-fixture
            const formDataArray = geoArray.map(geo => {
                const geoWS = geo.points.map(point => ({x: point.x + insertPoint.x, y: (point.y * -1) - insertPoint.y}));
                console.log('Inserting world space geo points:', geoWS);
                //const sourcePoint = insertPoint;
                const sourcePoint = this.smartMapUtils.getClosestVertex(insertPoint, geoWS);
                const size = fixture.bounds.size();

                const newFixture = {
                    "near_rack_id": 0,
                    "sx": sourcePoint.x,
                    "sy": sourcePoint.y,
                    "dx": sourcePoint.x,
                    "dy": sourcePoint.y,
                    "rackGeo": this.smartMapUtils.getGeometryFromPoints(geoWS),
                    "centerX": fixture.bounds.center().x,
                    "centerY": fixture.bounds.center().y,
                    "rack_height": fixture.fixtureData.HEIGHT,
                    "rack_depth": size.height,
                    "rack_width": size.width,
                    "rack_type_id": this.insertFixtureParams.rack_type_id,
                    "rack_description": this.insertFixtureParams.fixture_description,
                    "rotation": 0,
                    "pointCount": geoWS.length,
                    "rack_type": fixture.fixtureData.ID,
                    "flagMoveProcess": false
                };

                const formData = new FormData();
                Object.keys(newFixture).forEach(key => formData.append(key, newFixture[key]));
                return formData;
            });

            const editFixtureJson = await this.smartMap.applyMultipleFixtureEDITS(this.props.apiUrl, this.props.inputParams, formDataArray, this.state.currentAction);
            console.log("________________________", editFixtureJson);
            this.resetMapAction();
            await this.refreshMap();
        } catch (error) {
            this.insertFixtureParams = { fixtureGeo: undefined, fixture_description: undefined, rack_type_id: 0, resizable: 0, skip_process: 0 }
            throw new Error(`fixture edits action ${this.state.currentAction} failed: ${error}`);
        }

    }
    splitFixtureEvent(splitCnt) {
        if (this.state.selectedFixture && this.state.currentAction === "splitFixture") {
            let formData = new FormData();
            let splitRackId = this.state.selectedFixture.fixtureData.RACK_ID
            formData.append('rack_id', splitRackId);
            formData.append('splitCount', splitCnt);
            let transformFixClickPt = this.smartMapUtils.getSvgPoint(this.state.mouseClickPosOnFix.x, this.state.mouseClickPosOnFix.y);
            formData.append('x', transformFixClickPt.x);
            formData.append('y', transformFixClickPt.y*-1);
            //console.log("container->splitFixtureEvent-> formData", splitRackId, splitCnt, this.state.mouseClickPosOnFix, transformFixClickPt, this.state.currentAction);
            this.fixtureEDITS(formData);
        }
    }
    mergeFixtureEvent(rack_id2) {
        if (this.state.selectedFixture && this.state.currentAction === "mergeFixture") {
            let formData = new FormData();
            //let rack_id2 = this.state.selectedFixture.fixtureData.RACK_ID
            formData.append('rack_id1', this.state.preSelectedFixId);
            formData.append('rack_id2', rack_id2);
            console.log("container->mergeFixtureEvent-> formData", this.state.preSelectedFixId, rack_id2, this.state.currentAction);
            this.fixtureEDITS(formData);
        }
    }
    switchDescriptionEvent(desc){
        let processor = new StoreMapProcessor();
        let labelGroups = processor.getGroupsByLabelGroupKey(this.state.storeData.fixtures,desc,this.props.inputParams);
        this.props.inputParams.labelField=desc;
        this.setState(prevState => ({
            storeData: {
                ...prevState.storeData,
                labelGroups: labelGroups
            }
        }))
        /*
        this.props.inputParams.labelField=desc;        
        let storeDataNew = await processor.processFixtures(this.props.inputParams, this.state.storeData.fixtureData);
        this.setState((state) => ({
            storeData: storeDataNew,
            inputParams: this.props.inputParams
        }));
        */
    }
    clickModalClose(event) {
        if(this.disableClicks) return;
        this.setState(() => ({
            modalOpen: false
        }));

        event.stopPropagation();
    }

    clickModalRefresh(event) {
        if(this.state.selectedFixture) {
            this.updateFixtures([this.state.selectedFixture.fixtureData.RACK_ID]);
        }
    }

    renderLoading() {
        if(!this.state.error.error)
            return (
                <div className={this.state.storeData === undefined ? "cssload-spin-box" : ""}></div>
            )

        return <p>{this.state.error.message}</p>;
    }
    renderMapEditLoading() {
        if (this.state.currentAction) {
            let div = document.createElement('div');
            div.id = 'editSpinBox';
            div.className = 'cssload-spin-box';
            document.getElementById('smart-map').appendChild(div);
        }
    }
    renderRackInfo() {
        //Added parameter app_name: it is not been added at clickNothing and commented at getRackInfoUrl method.
        return (
            <RackInfo className="modal" title="rackinfo" scrolling="no" frameBorder="0"
                modalChild={this.state.modalChild}
                url={this.state.modalUrl + `&app_name=${"react_app"}`} onClose={this.clickModalClose} onRefresh={this.clickModalRefresh}>
            </RackInfo>
        )
    }

    getToolTips(fixture) {
        const toolTips = fixture.fixtureData.TOOL_TIP;
        const names = toolTips ? Object.entries(toolTips).map((tip, index) => {
            return (
                <div
                    key={index}
                >
                    {tip[0]}: {tip[1]}
                    <br/>
                </div>
            );
        }) : null;

        return names;
    }

    getViewBox(windowSize, svgSize, panX, panY, zoom) {
        const viewBoxSize = {
            width: svgSize.width * zoom,
            height: svgSize.height * zoom
        }
        const viewBoxLocation = {
            x: panX - windowSize.width * 0.5 * zoom,
            y: panY - windowSize.height * 0.5 * zoom
        }

        return {location: viewBoxLocation, size: viewBoxSize};
    }

    changeValue(value) {
        this.setState((state) => (
            {
                value: value
            }
        ));
    }

    renderStore() {
        const svgSize = {
            width: this.state.storeData.size.width,
            height: this.state.storeData.size.height,
        }
        this.width = this.state.storeData.size.width;
        this.height = this.state.storeData.size.height;
        /*const windowSize = {
            width: this.props.windowWidth,
            height: this.props.windowHeight
        }*/
        //const viewBox = this.getViewBox(windowSize, svgSize, this.state.panX, this.state.panY, this.state.zoom);
        return (
            <div className="svg-container" 
                //onWheel={this.handleWheel}
                onClick={this.clickNothing} onMouseDown={this.startDrawing} onMouseUp={this.endDrawing} onTouchMove={this.onMouseMove} onTouchEnd={this.onMouseLeave} onMouseMove={this.onMouseMove} onMouseLeave={this.onMouseLeave}
            >
            <div style={{width: "100%", height: "100%"}}>
            <AutoSizer>
            {(({width, height}) => width === 0 || height === 0 ? null : (
                <ReactSVGPanZoom width={width} height={height}
                    ref={viewer => this.viewer = viewer}
                    tool={this.state.tool}
                    onChangeTool={tool => this.setState({tool})}
                    value={this.state.value}
                    onChangeValue={this.changeValue}
                    preventPanOutside={false}
                    detectAutoPan={false}
                    customToolbar={FitToScreenToolbar}
                    background="#ffffff"
                    miniatureProps={{
                        background: "#ffffff"
                    }}
                >
                <svg id="mysvg" width={svgSize.width} height={svgSize.height}>
                    <SVGMap
                        onUpdated={this.updated}
                        storeData={this.state.storeData}
                        storeExtents={this.state.storeExtents}
                        fixtureBounds={this.state.storeData.totalBounds}
                        fillColor={this.state.inputParams.fillColor}
                        lineColor={this.state.inputParams.lineColor}
                        onClickFixture={this.clickFixture}
                        onEnterFixture={this.enterFixture}
                        onLeaveFixture={this.leaveFixture}
                        renderLabelGroups={!this.state.renderFast}
                        selectedFixture={this.state.selectedFixture}
                        storeMapImage={this.getStoreImageUrl()}
                        debug={this.state.inputParams.debug}
                        currentAction={this.state.currentAction}
                        onClickMarker={this.clickMarker}
                    >
                    </SVGMap>
                </svg>
                </ReactSVGPanZoom>
            ))}
            </AutoSizer>
            {(this.state.tooltip && this.state.hoveredFixture !== undefined) ?
                    <div className="tooltip"
                        style={{
                            top:this.state.mousePos.y,
                            left:this.state.mousePos.x + 24,
                        }}
                    >
                        {this.getToolTips(this.state.hoveredFixture)}
                    </div>
                : ""}
            </div>
            </div>
        )
    }

    render() {
        return (
            <div className="smart-map" id="smart-map">
                {this.state.storeData === undefined ? this.renderLoading() : this.renderStore()}
                {this.state.modalOpen === false || this.state.storeData === undefined ? "" : this.renderRackInfo()}
            </div>
        )
    }

    async componentDidMount() {

        const url = this.props.apiUrl;
        const params = this.props.inputParams;

        try {
            const storeExtentsData = await this.smartMap.fetchStoreExtents(url, params, params.testData);
            const storeData = await this.smartMap.fetchStore(url, params, params.testData,this.props.inputExtraParams);
            //const storeFixtureTypesData = await this.smartMap.fetchFixtureTypes(url, params);

            this.setState((state) => ({
                storeData: storeData,
                storeExtents: storeExtentsData,
                inputParams: params,
            }));

            //this.setZoomFromProp();
            //this.setPanFromProp();
            //this.setupTouchGestures();
            this.viewer.fitToViewer();

            document.addEventListener('mousemove', this.mouseMove, false);
            //window.postMessage({function: 'selectFixtureById', param: 16}, 'http://storemap.retailgis.com:3000');
            //window.postMessage({function: 'refreshFixturesByIds', param: {ids: [4735], extraParams: "&theme=3"}}, 'http://storemap.retailgis.com:3000');
            let svg = document.getElementsByTagName('svg')[0];
            let transformed = svg.getElementById('transformed');
            this.smartMapUtils = new SVGMapUtils(svg,transformed,this.state.storeData.fixtureData);
        } catch(error) {
            this.setState(() => ({error: {error: true, message: `${error}`}}));
        }
    }

    async updateFixtures(fixtureIds, extraParams='') {

        const url = this.props.apiUrl;
        const params = this.props.inputParams;

        try {
            //const storeExtentsData = await this.smartMap.fetchStoreExtents(url, params, extraParams);
            const storeData = await this.smartMap.fetchFixtureIds(url, params, fixtureIds, this.state.storeData, extraParams);

            this.setState(() => ({
                storeData: storeData,
                //storeExtents: storeExtentsData,
                inputParams: params
            }));
            let svg = document.getElementsByTagName('svg')[0];
            let transformed = svg.getElementById('transformed');
            this.smartMapUtils = new SVGMapUtils(svg,transformed,storeData.fixtureData);
        } catch(error) {
            console.log(`Refresh fixture error:`, error);
            this.setState(() => ({error: {error: true, message: `${error}`}}));
        }
    }

    setZoomFromProp() {
        const zoomInput = this.props.inputParams.zoom;
        const zoom =  zoomInput === 0 ? 0.001 : zoomInput;
        this.zoomAbsolute(1/zoom);
    }

    setPanFromProp() {
        const autoFocus = this.state.inputParams.autoFocus;
        const totalBounds = this.state.storeData.totalBounds;
        const focusPos = totalBounds.center();
        const viewPan = {
            x: autoFocus === "true" ? focusPos.x : this.props.inputParams.panX,
            y: autoFocus === "true" ? focusPos.y : this.props.inputParams.panY
        }
        this.panAbsolute(viewPan);
    }

    setupTouchGestures() {

        const elem = document.getElementsByClassName('svg-container');
        const manager = new Hammer.Manager(elem[0]);

        manager.add( new Hammer.Pinch({ direction: Hammer.DIRECTION_ALL, threshold: 0 }) );
        manager.on("pinchstart", this.handleTouchPinchStart);
        manager.on("pinchmove", this.handleTouchPinchMove);
        manager.on("pinchend", this.handleTouchPinchStart);

        manager.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }) );
        manager.on("panstart", this.handleTouchPanStart);
        manager.on("panmove", this.handleTouchPanMove);
        manager.on("panend", this.handleTouchPanEnd);
    }

    getStoreImageUrl() {
        const {retailerId, divisionId, store} = this.props.inputParams;
        return `${this.props.storeMapUrl}/${retailerId}/${divisionId}/${retailerId}_${divisionId}_${store}.gif`;
    }

    handleTouchPanStart(event) {
        this.setRenderFast(true);
        this.prevPanPos = {x: event.pointers[0].x, y: event.pointers[0].y};
        this.disableClicks = true;
    }

    handleTouchPanMove(event) {
        const delta = { x: event.pointers[0].x - this.prevPanPos.x , y: event.pointers[0].y - this.prevPanPos.y};
        this.prevPanPos = {x: event.pointers[0].x, y: event.pointers[0].y};
        this.pan(delta);
    }

    handleTouchPanEnd(event) {
        this.setRenderFast(false);
        this.setClickEnableTimer();
    }

    handleTouchPinchStart(event) {
        this.setRenderFast(true);
        this.prevZoom = event.scale;
        this.disableClicks = true;
    }

    handleTouchPinchMove(event) {
        const delta = event.scale - this.prevZoom;
        this.prevZoom = event.scale;
        this.zoom(delta * this.state.zoom * -1);
    }

    handleTouchPinchEnd(event) {
        this.setRenderFast(false);
        this.setClickEnableTimer();
    }

    setClickEnableTimer() {
        setTimeout(() => {
            this.disableClicks = false;
        }, 300);
    }

    setRenderFast(doRenderFast) {
        this.setState(() => ({
            renderFast: doRenderFast,
        }));
    }

    pan(delta) {
        this.setState(() => ({
            panX: this.state.panX - delta.x * this.state.zoom * 2,
            panY: this.state.panY - delta.y * this.state.zoom * 2
        }));
    }

    panAbsolute(value) {
        this.setState(() => ({
            panX: value.x,
            panY: value.y
        }));
    }

    zoom(delta) {
        this.setState(() => ({
            zoom: this.state.zoom + delta,
        }));
    }

    zoomAbsolute(value) {
        this.setState(() => ({
            zoom: value,
        }));
    }

    handleWheel(event) {
        const delta = event.deltaY * 0.001;
        this.zoom(delta);
    }   
    async fixtureEDITS(formData) {
        const url = this.props.apiUrl;
        const params = this.props.inputParams;
        const editFixtureJson = await this.smartMap.applyFixtureEDITS(url, params, formData, this.state.currentAction);
        //console.log("editFixtureJson",editFixtureJson);
        await this.refreshMap();
        await this.resetMapAction();
        return editFixtureJson;
    }
    async refreshMap() {
        let now = moment();
        //console.log("before chgdate",now.format('YYYY-MM-DD hh:mm:ss'));
        now.subtract('2', 'minutes');
        let chgdate = now.format('YYYY-MM-DD hh:mm:ss');
        //console.log("after chgdate",chgdate);
        this.updateFixtures([0, 0], "&chgdate=" + chgdate);
    }     
    async deleteSelectedFixtures() {
        if (this.state.selectedFixture) {
            this.renderMapEditLoading()
            let deleteRackId = this.state.selectedFixture.fixtureData.RACK_ID
            let formData = new FormData();
            if(/multi/.test(this.state.currentAction) === true){
                let rtnDetails = this.getSelectedMultiFixtureDetails();
                formData.append('list_rack_ids', rtnDetails.rackIdsCsv);
            } else
                formData.append('rack_id', deleteRackId);
            try {
                await this.fixtureEDITS(formData);
            } catch (error) {
                this.resetMapAction()
            }
        }
    } 
    processRotateFixture(angle) {
        let refreshRotateFixture = (rotateFixtureId) => {
            this.resetMapAction();
            //console.log("rotateFixtureId", rotateFixtureId);
            if (isNaN(rotateFixtureId) === false)
                this.selectFixtureById(rotateFixtureId);
        };
        let rotateFixture = (angle) => {
            if ((this.state.currentAction === "rotateFixture" || /multi/.test(this.state.currentAction) === true) && this.state.selectedFixture) {
                this.renderMapEditLoading()
                //console.log(this.renderMapEditLoading());
                /* let svg = document.getElementsByTagName('svg')[0];
                let selectedPolygon = svg.getElementById(this.state.selectedFixture.fixtureData.RACK_ID);
                selectedPolygon.setAttribute("transform", "rotate("+angle+")"); */
                let formData = new FormData();
                if(/multi/.test(this.state.currentAction) === true){
                    let rtnDetails = this.getSelectedMultiFixtureDetails();
                    formData.append('list_rack_ids', rtnDetails.rackIdsCsv);
                } else                
                    formData.append('rack_id', this.state.selectedFixture.fixtureData.RACK_ID);
                formData.append('rotationAngle', angle);
                this.fixtureEDITS(formData);
                let rotateFixtureId = this.state.selectedFixture.fixtureData.RACK_ID
                this.updateFixtures([this.state.selectedFixture.fixtureData.RACK_ID, 0], "");
                /* anime.set({
                    targets: selectedPolygon,
                    rotate: 45
                }); */
                return rotateFixtureId
            } else {
                return 0
            }
        };
        new Promise(function (resolve, reject) {
            try {
                 resolve(rotateFixture(angle))   
            } catch (error) {
                reject(error);
            }
        }).then(
            function (rotateFixtureId) {
                refreshRotateFixture(rotateFixtureId)
            }
        )
    }
    getSelectedMultiFixtureDetails() {
        let rackIdsCsv = "";
        let fixIdsArray = [];
        let polygonAccumPntsArray = [];
        this.state.selectedFixturesArray.forEach((fix, index) => {
            //console.log("------------------",fix,fix.geometry[0],fix.geometry[0].points.length)
            if (fixIdsArray.includes(fix.fixtureData.RACK_ID) === false) {
                for (let i = 0; i < fix.geometry[0].points.length; i++) {
                    polygonAccumPntsArray.push(fix.geometry[0].points[i]);
                }
                fixIdsArray.push(fix.fixtureData.RACK_ID)
                rackIdsCsv = rackIdsCsv + fix.fixtureData.RACK_ID + ","
            }
        })
        rackIdsCsv = rackIdsCsv.replace(/(\s*,?\s*)*$/, "");
        return {rackIdsCsv, polygonAccumPntsArray}
    }
    startDrawing = e => {
        if (this.state.currentAction && this.state.drawMode === true) {
            let startPnt = this.smartMapUtils.getSvgPoint(this.state.mousePos.x, this.state.mousePos.y);
            startPnt = { x: startPnt.x, y: startPnt.y };
            this.setState(() => ({
                drawMode: true,
                drawStart: startPnt,
                drawEnd: startPnt
            }));
            if (this.state.drawSelect === null && document.getElementById('selectorDrawn') === null) {
                let drawStart = { x: 0, y: 0 }
                let drawEnd = { x: 0, y: 0 }
                let path = `M${drawStart.x} ${drawStart.y} v ${drawEnd.y - drawStart.y} h ${drawEnd.x -
                    drawStart.x} v ${drawStart.y - drawEnd.y} Z`;
                let gTransformed = document.getElementById('transformed');
                let newpath = document.createElementNS("http://www.w3.org/2000/svg", "path");
                newpath.setAttributeNS(null, "id", "selectorDrawn");
                newpath.setAttributeNS(null, "d", path);
                newpath.setAttributeNS(null, "stroke", "black");
                newpath.setAttributeNS(null, "stroke-width", "3");
                newpath.setAttributeNS(null, "opacity", 0.3);
                newpath.setAttributeNS(null, "fill", "green");
                gTransformed.appendChild(newpath);
                this.setState(() => ({
                    drawSelect: newpath
                }));
            } else {
                this.renderSelector()
            }
            //console.log("startDrawing--->", e.pageX, e.pageY, this.state.mousePos.x, this.state.mousePos.y, startPnt, this.state)
        }
        //e.stopPropagation();
    };
    onMouseMove = e => {
        //e.preventDefault();
        let sourcePoint
        let endPnt = this.smartMapUtils.getSvgPoint(this.state.mousePos.x, this.state.mousePos.y);
        if(e.type === "touchmove"){
            //console.log("onMouseMove -> touchMove -> XY ",e,e.touches[0].pageX,e.touches[0].pageY);
            endPnt = this.smartMapUtils.getSvgPoint(e.touches[0].pageX, e.touches[0].pageY);
        }
        endPnt = { x: endPnt.x, y: endPnt.y }; 
        if(e.ctrlKey)
            return false;
        let svg = document.getElementsByTagName('svg')[0];
        if (this.state.drawMode === true) {
            //console.log("onMouseMove--->", e, e.pageX, e.pageY, this.state.mousePos.x, this.state.mousePos.y)
            this.setState(() => ({
                drawEnd: endPnt
            }));
            if (this.state.drawSelect !== null && document.getElementById('selectorDrawn')) {
                this.renderSelector()
            }
        } else if (/copyFixture/i.test(this.state.currentAction) === true || /moveFixture/i.test(this.state.currentAction) === true) {
            let gTransformed = document.getElementById('transformed');
            let snapline = document.createElementNS("http://www.w3.org/2000/svg", "line");
            let snaplineTxt = document.createElementNS("http://www.w3.org/2000/svg", "text");  
            //console.log("-----------------",this.state.currentAction,"------------------",endPnt);

            let snapLineDrawn = document.getElementById('snapLine');
            let snapLineTxt = document.getElementById('snapLineAction');
            if (snapLineDrawn !== null) {
                snapLineDrawn.parentNode.removeChild(snapLineDrawn);
                snapLineTxt.parentNode.removeChild(snapLineTxt);
            }
            if(/multi/.test(this.state.currentAction) === true){
                let rtnDetails = this.getSelectedMultiFixtureDetails();
                //let srcRackId = rtnDetails.rackIdsCsv;
                let polygonAccumPntsArray = rtnDetails.polygonAccumPntsArray;
                sourcePoint = {x:0, y:0}
                sourcePoint = this.smartMapUtils.getBottomLeftVerttex(polygonAccumPntsArray);
            } else{
                if(this.state.selectedFixture){
                    let selectedPolygon = svg.getElementById(this.state.selectedFixture.fixtureData.RACK_ID);
                    let transformFixClickPt = this.smartMapUtils.getSvgPoint(this.state.mouseClickPosOnFix.x, this.state.mouseClickPosOnFix.y);
                    sourcePoint = this.smartMapUtils.getClosestVertex(transformFixClickPt, selectedPolygon.points);
                }
            }
            if(this.state.selectedFixture || this.state.selectedFixturesArray.length > 0){
                //let nearRackId
                this.smartMapUtils.getSnapPoint(endPnt.x, -1*endPnt.y, this.state.snapRadius).then((snapObj => {
                    if (snapObj && snapObj.snapPoint && snapObj.destRACKID !== 0) {
                        //console.log("onMouseMove-> (snapObj && snapObj.snapPoint && snapObj.destRACKID", snapObj, snapObj.snapPoint, snapObj.destRACKID);
                        //console.log("onMouseMove-> endPnt", endPnt);
                        
                        /*if (snapObj.destRACKID)
                            nearRackId = snapObj.destRACKID; */
                        if (snapObj.snapPoint)
                        endPnt = snapObj.snapPoint;
                        //console.log("onMouseMove-> snapObj (endPnt)", snapObj,endPnt,snapObj.snapPoint);

                        //let angle = this.smartMapUtils.angle(sourcePoint.x,sourcePoint.y,endPnt.x,endPnt.y);

                        snapline.setAttributeNS(null, "id", "snapLine");
                        snapline.setAttributeNS(null, "x1", sourcePoint.x);
                        snapline.setAttributeNS(null, "y1", -1*sourcePoint.y);
                        snapline.setAttributeNS(null, "x2", endPnt.x);
                        snapline.setAttributeNS(null, "y2", -1*endPnt.y);
                        snapline.setAttributeNS(null, "stroke", "red");
                        snapline.setAttributeNS(null, "stroke-width", "4");
                        snapline.setAttributeNS(null, "stroke-opacity", 2);
                        snapline.setAttributeNS(null, "fill", "red");
                        snapline.setAttributeNS(null, "stroke-dasharray", "15 10");
                        gTransformed.appendChild(snapline);

                        snaplineTxt.setAttributeNS(null, "id", "snapLineAction");
                        snaplineTxt.setAttributeNS(null, "x", (parseInt(sourcePoint.x)+parseInt(endPnt.x))/2-(this.state.currentAction.length*6/2));
                        snaplineTxt.setAttributeNS(null, "y", -1*(parseInt(sourcePoint.y)+parseInt(endPnt.y))/2+5);
                        snaplineTxt.setAttributeNS(null, "class", "snap");
                        
                        var actionTxt = document.createTextNode(this.state.currentAction);
                        //actionTxt.setAttribute("transform", `rotate(${Math.floor(angle)})`);
                        snaplineTxt.appendChild(actionTxt);
                        //console.log("onMouseMove-> snaplineTxt", snaplineTxt,sourcePoint.x,endPnt.x,(parseInt(sourcePoint.x)+parseInt(endPnt.x))/2,Math.floor(angle));
                        gTransformed.appendChild(snaplineTxt);
                    }
                }));
            }
        };/* else {
            //let transformed = svg.getElementById('transformed');
            let CTM = svg.getScreenCTM();
            let selectedElement = e.target;
            let selectedPolygon;
            if(this.state.selectedFixture){
                selectedPolygon = svg.getElementById(this.state.selectedFixture.fixtureData.RACK_ID);
                e.preventDefault();
                var coord = {x:(e.clientX - CTM.e) / CTM.a ,y: (e.clientY - CTM.f) / CTM.d};
                selectedPolygon.setAttributeNS(null, "x", (e.clientX - CTM.e) / CTM.a);
                selectedPolygon.setAttributeNS(null, "y", (e.clientY - CTM.f) / CTM.d);
            }
            //console.log("onMouseMove--->", (e.clientX - CTM.e) / CTM.a,(e.clientY - CTM.f) / CTM.d,this.state.selectedFixture,selectedElement,selectedPolygon);
        }*/
        //e.stopPropagation();
    };
    onMouseLeave = e => {
        /*
        let svg = document.getElementsByTagName('svg')[0];
        let transformed = svg.getElementById('transformed');
        let CTM = svg.getScreenCTM();
        */
        //console.log("onMouseLeave--->", e,CTM,(e.clientX - CTM.e) / CTM.a,(e.clientY - CTM.f) / CTM.d);
    };
    adjustToScreenRatio(width, height, startRatio, targetRatio) {
        // Return width and height in same ratio as target ratio,
        // while retaining longest dimension

        if(startRatio > 1 && targetRatio < 1) {
            return {width: width, height: height};
        } else if (startRatio < 1 && targetRatio > 1){
            return {width: width, height: width * targetRatio};
        }
    }
    endDrawing = e => {
        //e.preventDefault();
        if (this.state.currentAction && this.state.currentAction === "zoomRect") {

            const xStart = this.state.drawStart.x;
            const yStart = this.state.drawStart.y;
            const xEnd = this.state.drawEnd.x;
            const yEnd = this.state.drawEnd.y;

            const xMin = Math.min(xStart, xEnd);
            const xMax = Math.max(xStart, xEnd);

            const yMin = Math.min(yStart, yEnd);
            const yMax = Math.max(yStart, yEnd);

            const bounds = new Bounds(xMin, xMax, yMin, yMax, null, null, null, null, undefined);
            //const { width: selWidth, height: selHeight } =  bounds.size();

            const totalBounds = this.state.storeData.totalBounds;
            const xTransform = -1 * totalBounds.xMin;
            const yTransform = -1 * totalBounds.yMin;

            const windowRatio = window.innerHeight / window.innerWidth;
            //const selectionRatio = selHeight / selWidth;

            const paddedBounds = bounds.padToRatio(windowRatio);

            const { width: paddedWidth, height: paddedHeight } =  paddedBounds.size();
            this.viewer.fitSelection(paddedBounds.xMin + xTransform, paddedBounds.yMin + yTransform, paddedWidth, paddedHeight);
        }
        if (this.state.currentAction && this.state.drawMode) {
            //console.log("endDrawing--->", this.state, e, e.pageX, e.pageY, this.state.mousePos.x, this.state.mousePos.y, inRange(this.state.drawStart.x, this.state.drawEnd.x), inRange(this.state.drawStart.y, this.state.drawEnd.y))
            /* const xRange = inRange(this.state.drawStart.x, this.state.drawEnd.x);
            const yRange = inRange(this.state.drawStart.y, this.state.drawEnd.y); */
            this.setState(state => {
                const newState = {
                    drawMode: false
                };
                if (this.state.drawStart === this.state.drawEnd) {
                    return {
                        ...newState,
                        drawStart: null,
                        drawEnd: null
                    };
                }
                return newState;
            });
            if (this.state.drawSelect !== null) {
                this.renderSelector();
                let currentFixSelectionArray = [];
                if (/multiFixture/i.test(this.state.currentAction) === true) {
                    this.state.storeData.fixtures.forEach((f) => {
                        const foundFixture = this.state.selectedFixturesArray.find(fix => fix.fixtureData.RACK_ID === f.fixtureData.RACK_ID);
                        if (f.origin && this.pointInRange(f.origin)) {
                            currentFixSelectionArray.push(f);
                            this.setState(() => ({
                                selectedFixture: f
                            }));
                            if (foundFixture === undefined) {
                                this.state.selectedFixturesArray.push(f);
                                anime({
                                    targets: document.getElementById(f.fixtureData.RACK_ID),
                                    strokeWidth: 2,
                                    stroke: "#0000ff",
                                    fill: chroma.mix(f.fixtureData.BAYLOCK_COLOR ? f.fixtureData.BAYLOCK_COLOR : this.props.fillColor, "00ff00", 0.75).hex()
                                });

                                this.focusFixtures(this.state.selectedFixturesArray);
                            }
                        }
                    })
                }
                let selectorDrawn = document.getElementById('selectorDrawn');
                if (selectorDrawn !== null) {
                    selectorDrawn.parentNode.removeChild(selectorDrawn);
                    this.setState(() => ({
                        currentAction: currentFixSelectionArray.length === 0 ? undefined : this.state.currentAction,
                        tool: TOOL_AUTO,
                        drawMode: false,
                        drawStart: null,
                        drawEnd: null,
                        drawSelect: null,
                        modalUrl: (/multiFixture/i.test(this.state.currentAction) === true) ? this.getMultiRackInfoUrl() : this.props.defaultModalUrl + this.props.inputParamsString,
                        modalChild: undefined,
                        modalOpen: true 
                    }));
                }
            }
            //console.log("endDrawing--->", this.state, xRange, yRange, this.shapeIsDrawn(),this.state.selectedFixturesArray)
        }
        //e.stopPropagation();
    }; 
    pointInRange = point => {
        const { drawStart, drawEnd } = this.state;
        const xRange = inRange(drawStart.x, drawEnd.x);
        const yRange = inRange(drawStart.y, drawEnd.y);

        return xRange(point.x) && yRange(point.y);
    };
    shapeIsDrawn() {
        const { drawStart, drawEnd } = this.state;
        return drawStart && drawEnd;
    }
    renderSelector() {
        if (this.shapeIsDrawn() && this.state.drawSelect !== null && document.getElementById('selectorDrawn')) {
            const { drawStart, drawEnd } = this.state;
            const path = `M${drawStart.x} ${drawStart.y} v ${drawEnd.y - drawStart.y} h ${drawEnd.x - drawStart.x} v ${drawStart.y - drawEnd.y} Z`;
            document.getElementById('selectorDrawn').setAttributeNS(null, "d", path);
            /* return (
              <path
                d={path}
                stroke="blue"
                strokeWidth="3px"
                fill="green"
                fillOpacity="0.3"
              />
            ); */
        }
        return null;
    }
}
