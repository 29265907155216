import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }
  
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true, error: error });
        console.log("Something went wrong!");
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div>
                <h1>Something went wrong.</h1>
                <p>{this.state.error.message}</p>
            </div>
            )
      }
      return this.props.children;
    }
}