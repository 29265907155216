import StoreMapProcessor from './store-map-processor';
import Bounds from './util/bounds.js';
import Fixture from './models/fixture';
import FixtureData from './models/fixture-data';

const testFixtureData = require('./data/test-fixtures.json');
const testExtentsData = require('./data/test-extents.json');

export default class SmartMapService
{
    async fetchStoreExtentsTest() {
        try {

            const {STORE_MINX, STORE_MINY, STORE_MAXX, STORE_MAXY} = testExtentsData;
            const storeBounds = new Bounds(STORE_MINX, STORE_MAXX, STORE_MINY, STORE_MAXY);
            return storeBounds;

        } catch(error) {
            throw new Error(`Store extents test data load failed: ${error}`);
        }
    }

    async fetchStoreExtents(apiUrl, params, useTestData=false, extraParams="") {

        try {

            if(useTestData)
                return this.fetchStoreExtentsTest();

            const {retailerId, divisionId, store, layers, user} = params;
            const extentsUrl = `${apiUrl}?action=getExtents&retailer_id_no=${retailerId}&store_no=${store}&division_id_no=${divisionId}&layers=${layers}&user=${user}${extraParams}`;
            const storeExtents = await fetch(extentsUrl, {
                credentials: 'include'
              });

            if(storeExtents.ok) {
                const storeExtentsJson = await storeExtents.json();
                const {STORE_MINX, STORE_MINY, STORE_MAXX, STORE_MAXY,FOS_ORIGINVALUE1,FOS_ORIGINVALUE2,FOS_RIGHTVALUE1,FOS_RIGHTVALUE2,GEO} = storeExtentsJson;
                const storeBounds = new Bounds(STORE_MINX, STORE_MAXX, STORE_MINY, STORE_MAXY,FOS_ORIGINVALUE1,FOS_ORIGINVALUE2,FOS_RIGHTVALUE1,FOS_RIGHTVALUE2,GEO);
                return storeBounds;
            } else {
                console.log('Request failed: ', storeExtents);
                throw new Error(`Bad reply from: ${extentsUrl}`);
            }
        } catch(error) {
            //throw new Error(`Store extents fetch failed: ${error}`);
            throw new Error(`INVALID SECURITY INFORMATION`);
        }
    }

    async fetchStoreTest(params) {

        try {
            const fixturesJson = testFixtureData;

            if(fixturesJson.length > 2) {
                const processor = new StoreMapProcessor();
                const storeData = await processor.processFixtures(params, fixturesJson);
                return storeData;

            } else {
                throw new Error("No fixtures fetched, check store parameters");
            }

        } catch(error) {
            throw new Error(`Store fetch failed: ${error}`);
        }
    }

    async fetchStore(apiUrl, params, useTestData=false, extraParams="") {

        try {

            if(useTestData)
                return this.fetchStoreTest(params);

            const {retailerId, divisionId, store, layers, user, theme} = params;
            const url = `${apiUrl}?action=getFixtures&retailer_id_no=${retailerId}&store_no=${store}&division_id_no=${divisionId}&layers=${layers}&user=${user}&theme=${theme}${extraParams}`;

            const fixtures = await fetch(url, {
                credentials: 'include'
              });
            const fixturesJson = await fixtures.json();

            if(fixturesJson.length > 2) {

                const processor = new StoreMapProcessor();
                const storeData = await processor.processFixtures(params, fixturesJson);

                return storeData;

            } else {
                //throw new Error("No fixtures fetched, check store parameters");
                throw new Error(`INVALID SECURITY INFORMATION`);
            }

        } catch(error) {
            //throw new Error(`Store fetch failed: ${error}`);
            throw error;
        }
    }

    // fixtureId: id of the fixture to fetch / update
    // storeData: function will return copy of this with updated fixture
    // 15-Dec-2020: Removed comma in Join Call since it was giving a error
    async fetchFixtureIds(apiUrl, params, fixtureIds, currentStoreData, extraParams="") {

        try {
            const {retailerId, divisionId, store, layers, user} = params;
            const url = `${apiUrl}?action=getFixtures&retailer_id_no=${retailerId}&store_no=${store}&division_id_no=${divisionId}&layers=${layers}&user=${user}&list_refresh_rackids=${fixtureIds.join()}${extraParams}`;

            const fixtures = await fetch(url, {
                credentials: 'include'
              });
            const fixturesJson = await fixtures.json();

            if (fixturesJson.length > 2) {

                // Combine the new fixtures json with the data from the last request
                const newJson = currentStoreData.fixtureData.map((oldFixture) => {
                    const found = fixturesJson.find(element => element.RACK_ID === oldFixture.RACK_ID);
                    return found === undefined ? oldFixture : found
                });
                // Below code for new fixture and deleted fixture updates.   
                fixturesJson.forEach((f) => {
                    if (f.RACK_ID) {
                        const found = newJson.find(element => element.RACK_ID === f.RACK_ID);
                        if (found === undefined) {
                            newJson.push(f); // Add new fixture
                        }
                    } else if (f.DELETED_RACKS && f.DELETED_RACKS.length > 0) {
                        newJson.forEach((delFix, index) => {
                            let found = f.DELETED_RACKS.findIndex(element => element.DELETE_RACK_ID === delFix.RACK_ID);
                            if (found > -1) {
                                console.log("found", found,index,delFix.RACK_ID);
                                newJson.splice(index, 1) // remove deleted fixture from the map.
                            }
                        })
                    }
                });
                const processor = new StoreMapProcessor();
                const storeData = await processor.processFixtures(params, newJson, false);

                return storeData;

            } else {
                //throw new Error("No fixtures fetched, check store parameters");
                throw new Error(`INVALID SECURITY INFORMATION`);
            }
        } catch(error) {
            //throw new Error(`Fixture ID fetch failed: ${error}`);
            throw error;
        }
    }

    // EDITS fixture service call
    async applyFixtureEDITS(apiUrl, params, formData, currentAction,extraParams = "",useTestData = false) {
        try {
            const { retailerId, divisionId, store, layers, user } = params;
            const extentsUrl = `${apiUrl}?action=${currentAction}&retailer_id_no=${retailerId}&store_no=${store}&division_id_no=${divisionId}&layers=${layers}&user=${user}`;
            const editFixtureData = await fetch(extentsUrl, {
                method: 'POST',
                body: formData
            });

            if (editFixtureData.ok) {
                const editFixtureJson = await editFixtureData.json();
                return editFixtureJson;
            } else {
                throw new Error(`Bad reply from fixture action ${currentAction}: ${extentsUrl}`);
            }
        } catch (error) {
            throw new Error(`fixture edits action ${currentAction} failed: ${error}`);
        }
    }
    // Multiple EDITS fixture service call
    applyMultipleFixtureEDITS(apiUrl, params, arrayFormData, currentAction, extraParams = "", useTestData = false) {
        return new Promise((resolve, reject) => {
            try {

                var promises = [];
                const { retailerId, divisionId, store, layers, user } = params;
                const extentsUrl = `${apiUrl}?action=${currentAction}&retailer_id_no=${retailerId}&store_no=${store}&division_id_no=${divisionId}&layers=${layers}&user=${user}`;
                arrayFormData.forEach((formData, index) => {
                    var p = new Promise(function (resolve, reject) {
                        let applyFixtureEDITS = async (resolve, reject, extentsUrl, formData) => {
                            try {
                                let editFixtureData = await fetch(extentsUrl, {
                                    method: 'POST',
                                    body: formData
                                });
                                if (editFixtureData.ok) {
                                    const editFixtureJson = await editFixtureData.json();
                                    return resolve(editFixtureJson);
                                } else {
                                    throw new Error(`Bad reply from fixture action ${currentAction}: ${extentsUrl}`);
                                }
                            } catch (error) {
                                console.log("error", error);
                                return reject(error);
                            }
                        }
                        return setTimeout(() => { applyFixtureEDITS(resolve, reject, extentsUrl, formData) }, 2000 * index);
                    });
                    promises.push(p);
                })
                Promise.all(promises).then((rtnData => {
                    resolve(rtnData)
                }));
            } catch (error) {
                reject(new Error(`fixture edits action ${currentAction} failed: ${error}`));
            }
        })
    }    
    //fetch store fixture types
    async fetchFixtureTypes(apiUrl, params, useTestData=false, extraParams="") {
        try {
            const {retailerId, divisionId, store, layers, user} = params;
            const fixtureTypesUrl = `${apiUrl}?action=getFixtureTypes&retailer_id_no=${retailerId}&store_no=${store}&division_id_no=${divisionId}&layers=${layers}&user=${user}${extraParams}`;
            const storeFixtureTypes = await fetch(fixtureTypesUrl);

            if(storeFixtureTypes.ok) {
                const storeFixtureTypesJson = await storeFixtureTypes.json();

                const fixtures = storeFixtureTypesJson.map((f) => 
                    {
                        const fixtureData = new FixtureData(f);
                        const fixturesGeo = fixtureData.getMultiPolyPointGeometry();
                        const pointGeometry = fixturesGeo;
                        
                        const bounds = pointGeometry.reduce((acc, curr) => acc.expand(curr.getBounds()), pointGeometry[0].getBounds());
                        const origin = bounds.center();
                        const isEndCap = fixtureData.getIsEndCap();
                        return new Fixture(f, pointGeometry, origin, bounds, isEndCap);
                    }
                );

                return fixtures;
            } else {
                throw new Error(`Bad reply from: ${fixtureTypesUrl}`);
            }
        } catch(error) {
            console.log(error);
            throw new Error(`Store fixture types fetch failed: ${error}`);
        }
    }    
}
