export default class Vector2D
{
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    add(other) {
        return new Vector2D(this.x + other.x, this.y + other.y);
    }

    subtract(other) {
        return new Vector2D(this.x - other.x, this.y - other.y);
    }

    divide(other) {
        return new Vector2D(this.x / other.x, this.y / other.y);
    }

    scale(scalar) {
        return new Vector2D(this.x * scalar, this.y * scalar);
    }

    magnitude() {
        return Math.sqrt((this.x * this.x) + (this.y * this.y));
    }

    normalize() {
        let mag = this.magnitude();
        let div = new Vector2D(mag, mag);
        return this.divide(div);
    }

    distance(other) {

        const xs = other.x - this.x;
        const ys = other.y - this.y;

        const xsSquared = xs * xs;
        const ysSquared = ys * ys;

        return Math.sqrt( xsSquared + ysSquared );
    }

    mapToRectangle(rectWidth, rectHeight) {

        const d = Math.max(Math.abs(this.x)/rectWidth, Math.abs(this.y)/rectHeight);
        return new Vector2D(this.x / d, this.y / d).divide(new Vector2D(2, 2));
    }
}