import FixtureData from './models/fixture-data.js';
import Bounds from './util/bounds.js';
import Fixture from './models/fixture.js';
import LabelGroup from './models/label-group.js';

/* This class is used to process the data provided by the retailGIS smart map API.  
*/
export default class StoreMapProcessor
{
    /**
     * 
     * @param {*} fixtures Array of fixtures to calculate total bounds from
     */
    getAllFixtureBounds(fixtures) {
        
        let totalBounds = new Bounds();
        fixtures.forEach((f) => {
            if(f.bounds) 
            {
                totalBounds = totalBounds.expand(f.bounds);
            }
        });

        return totalBounds;
    }

    /**
     * Gets fixtures with matching labelFields within distanceThreshold.  Recursive;
     * grows list of fixtures until group is complete / no more are found.
     * @param {*} result Grouped fixtures
     * @param {*} current Fixture to test
     * @param {*} remaining List of ungrouped fixtures
     * @param {*} labelField Fixture data field name to group by, eg "PRODUCT_DESC"
     * @param {*} distanceThreshold Max distance to include fixture in group
     */
    getNearby(result, current, remaining, labelField, distanceThreshold) {

        let currentFixture = current;

        // Add to fixtures we've checked
        result.push(currentFixture);

        let currentLabel = currentFixture.fixtureData[`${labelField}`];

        // Gather list of fixtures near current fixture
        let nearby = remaining.filter((remainingFixture) => {

            let remainingLabel = remainingFixture.fixtureData[`${labelField}`];
            let dist = currentFixture.bounds.distanceFromEdge(remainingFixture.bounds);
            let isClose = dist <= distanceThreshold;
            if(isClose)
            {
                // Don't add end caps to group
                if(remainingFixture.isEndCap)
                    return false;

                // Don't group anything WITH an endcap
                if(currentFixture.isEndCap)
                    return false;

                // return currentLabel == remainingLabel
                return currentLabel === remainingLabel;
            }

            return false;
        });

        // Remove newly selected from remaining
        remaining = remaining.filter((remainingFixture) => { return !nearby.includes(remainingFixture); });

        // Did we grow?  If so, newly added fixtures need to be checked
        nearby.forEach((nextNearby) => {
            result.push(nextNearby);
            remaining = this.getNearby(result, nextNearby, remaining, labelField, distanceThreshold);
        });

        return remaining;
    }

    /**
     * Creates an array of LabelGroups by grouping fixtures using their LABEL_GROUP_KEY field
     * @param {*} fixtures Array of fixtures to generate array of LabelGroups from
     */
    
    getGroupsByLabelGroupKey(fixtures, labelField, distanceThreshold) {

        const startTime = new Date();
        let labelGroups = [];

        const groupKeys = [...new Set(fixtures.map(fixture => fixture.fixtureData.LABEL_GROUP_KEY))]

        groupKeys.forEach(key => {
            
            let groupFixtures = [];
            fixtures.forEach(fixture => {
                if(fixture.fixtureData.LABEL_GROUP_KEY === key) {
                    groupFixtures.push(fixture);
                }
            });

            if(groupFixtures.length > 0) {
                const label = groupFixtures[0].fixtureData[`${labelField}`];
                //Label group_distance logic tested -- no Issues --

                /*//let preSeq = 0;
                let preMidX = 0;
                let preMidY = 0;
                let groupSeqFixtures = [];
                //(preSeq === (f.fixtureData.SEQ - 1) || preSeq === (f.fixtureData.SEQ + 1))
                groupFixtures.forEach((f) => {
                    if ((preMidX === 0 && preMidY === 0) || (Math.abs(preMidX - f.origin.x) < distanceThreshold && Math.abs(preMidY - f.origin.y) < distanceThreshold)) {
                        groupSeqFixtures.push(f);
                        //console.log("getGroupsByLabelGroupKey 1",groupSeqFixtures,preMidX, "=== 0 &&", preMidY, "=== 0", "||", Math.abs(preMidX - f.origin.x) ,"<",  distanceThreshold ,"&&", Math.abs(preMidY - f.origin.y) ,"<",  distanceThreshold,f.fixtureData.SUB_AISLE_NO,f.fixtureData.RACK_ID,f.fixtureData.SEQ,f.origin,distanceThreshold);
                    } else {
                        labelGroups.push(new LabelGroup(label, groupSeqFixtures));
                        groupSeqFixtures = [];//groupSeqFixtures.splice(0, groupSeqFixtures.length);
                        groupSeqFixtures.push(f);
                        //console.log("getGroupsByLabelGroupKey 2",groupSeqFixtures,Math.abs(preMidX - f.origin.x) ,"<",  distanceThreshold ,"||", Math.abs(preMidY - f.origin.y) ,"<",  distanceThreshold );
                    }
                    //preSeq = f.fixtureData.SEQ;
                    preMidX = f.origin.x;
                    preMidY = f.origin.y;
                })
                if(groupSeqFixtures.length > 0)
                    labelGroups.push(new LabelGroup(label, groupSeqFixtures)); */

                // End of the  Label group_distance logic when you enable this code  comment bottom line.

                labelGroups.push(new LabelGroup(label, groupFixtures));
            }
        });

        console.log(`Fixture count: `, fixtures.length,labelField);
        //console.log(`Group keys: `, groupKeys);

        console.log(`Created label groups: `, labelGroups.length);

        const endTime = new Date() - startTime;
        console.log(`Gather groups by label group key execution time: ${endTime}ms`);
        
        return labelGroups;
    }

    /**
     * Creates an array of LabelGroups by fixture edge distance and matching label
     * @param {*} fixtures Array of fixtures to generate array of LabelGroups from
     * @param {*} labelField Fixture data field name to group by, eg "PRODUCT_DESC"
     * @param {*} distanceThreshold Max distance to include fixture in group
     */
    
    getGroups(fixtures, labelField, distanceThreshold) {

        const startTime = new Date();
        let labelGroups = [];

        // Create a copy of the fixtures array
        let remaining = fixtures.slice(0);

        while(remaining.length > 0)
        {
            let startingFixture = remaining.pop();
            let fixturesGroup = [];
            
            remaining = this.getNearby(fixturesGroup, startingFixture, remaining, labelField, distanceThreshold);

            const label = startingFixture.fixtureData[`${labelField}`];
            labelGroups.push(new LabelGroup(label, fixturesGroup));
        }

        const endTime = new Date() - startTime;
        console.log(`Gather groups execution time: ${endTime}ms`);

        return labelGroups;
    }
/*
    drawFixtures(canvas, storeInfo, fixtures) {
        
        fixtures.forEach( (f) => { f.draw(canvas, storeInfo); } );
    }
*/
/*
    drawLabelGroups(canvas, storeInfo, labelGroups) {
        
        labelGroups.forEach( (lg) => { lg.draw(canvas, storeInfo); } );
    }
*/
/*
    async getSVG(storeInfo) {
    
        try{

            let fixtureData = await this.getStoreFixtures(storeInfo);

            if(fixtureData.error) {
                throw fixtureData.error;
            }
            
            // Create Fixture object array from parsed point geometry and calculated bounds
            let fixtures = fixtureData.map((f) => 
                {
                    let fixtureData = new FixtureData(f);
                    let pointGeometry = fixtureData.getPointGeometry();
                    let bounds = pointGeometry.getBounds();
                    let origin = bounds.center();
                    let isEndCap = fixtureData.getIsEndCap();
                    return new Fixture(f, pointGeometry, origin, bounds, isEndCap);
                }
            );

            // Create label groups
            let labelGroups = this.getGroups(fixtures, storeInfo.labelField, storeInfo.groupDistance);

            // Calculate total bounds using preprocessed fixture bounds
            let totalBounds = this.getAllFixtureBounds(fixtures);
            let size = totalBounds.size();
            let scale = storeInfo.scale;

            const canvas = new SVG(document.documentElement).viewbox(totalBounds.xMin, totalBounds.yMin, size.width, size.height).size(size.width * scale, size.height * scale).clear();

            this.drawFixtures(canvas, storeInfo, fixtures);
            this.drawLabelGroups(canvas, storeInfo, labelGroups);

            return canvas.svg();

        } catch(error) {
            console.error(error);
            return error;
        }
    }
*/
    processFixtures(storeInfo, fixtureData, errorInfoIncludedAsFixtures=true) {

        return new Promise((resolve, reject) => {
                
            try {

                // For some reason, the Regis API attaches two additional 
                // items to the end of the fixture array.  
                const errorState = errorInfoIncludedAsFixtures ? fixtureData.pop() : null;
                const maxChangeDate = errorInfoIncludedAsFixtures ? fixtureData.pop() : null;

                // Create Fixture object array from parsed point geometry and calculated bounds
                /* const fixtures = fixtureData.map((f) => 
                    {
                        const fixtureData = new FixtureData(f);
                        const pointGeometry = fixtureData.getPointGeometry();
                        const bounds = pointGeometry.getBounds();
                        const origin = bounds.center();
                        const isEndCap = fixtureData.getIsEndCap();
                        return new Fixture(f, pointGeometry, origin, bounds, isEndCap);
                    }
                ); */
                const fixtures = [];
                let markers = [];
                fixtureData.forEach((f) => {
                    if (f.RACK_ID) {
                        const fixtureData = new FixtureData(f);
                        const pointGeometry = fixtureData.getPointGeometry();
                        const bounds = pointGeometry.getBounds();
                        const origin = bounds.center();
                        const isEndCap = fixtureData.getIsEndCap();
                        fixtures.push(new Fixture(f, pointGeometry, origin, bounds, isEndCap));
                    } else if (f.SMS && f.SMS.length > 0) {
                        markers = f.SMS;
                        //console.log("markers",markers);
                        /* f.SMS.forEach((marker) => {
                            console.log("marker",marker,marker.ID,marker.x,,marker.y);
                        }) */
                    }
                }); 
                // Create label groups
                const labelGroups = this.getGroupsByLabelGroupKey(fixtures, storeInfo.labelField,storeInfo.groupDistance);
                //const labelGroups = this.getGroups(fixtures, storeInfo.labelField, storeInfo.groupDistance);

                // Calculate total bounds using preprocessed fixture bounds
                const totalBounds = this.getAllFixtureBounds(fixtures);
                const size = totalBounds.size();

                const store = {
                    labelGroups: labelGroups,
                    totalBounds: totalBounds,
                    size: size,
                    maxChangeDate: maxChangeDate,
                    errorState: errorState,
                    fixtures: fixtures,
                    fixtureData: fixtureData,
                    markersData: markers
                };

                resolve(store);

            } catch(error) {
                reject(error);
            }
        })
    }
}