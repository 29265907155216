import React, { Fragment } from 'react';
import { isNumber } from 'util';

import "./label-group.scss";

const colors = require("../../lib/smartmap/data/colors.json");

export default class LabelGroup extends React.Component {

    // Props: labelGroup, labelGroupIndex

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // Break long labels up into multiple lines
    multiLineLabel(labels) {
        
        let lines = ['test line 1', 'test line 2'];
        return lines.map((line) => {
            return (
                <Fragment>
                <tspan textRendering="optimize-speed">{line}</tspan>
                </Fragment>
            );
        }).join();
    }

    renderLabelGroupDebug(x, y, angle, fontSize, label, groupX, groupY, bounds, cull) {
        return !cull ? (
            <Fragment>
            <rect x={groupX} y={groupY} width={bounds.width} height={bounds.height} 
                style={{
                        fill:colors[this.props.index],
                        stroke:colors[this.props.index],
                        strokeWidth:2,
                        mixBlendMode: "difference",
                        pointerEvents: "none"
                }}/>
            <text
                className="svg-text" 
                fontFamily="Arial Narrow" 
                fontSize={fontSize}
                fill="#000000" 
                textAnchor="start"
                dominantBaseline="hanging"
                lengthAdjust="spacingAndGlyphs"
                transform={"translate("+x+" "+y+") rotate("+angle+")"}
                textRendering="optimize-speed"
                y="2px"
            >
                {this.multiLineLabel([])}
            </text>
            </Fragment>
        ) : (null)
    }

    renderLabelGroup(x, y, angle, fontSize, label, groupX, groupY, bounds, cull) {
        return !cull ? (
            <text
                className="svg-text" 
                fontFamily="Arial Narrow" 
                fontSize={fontSize}
                fill="#000000" 
                textAnchor="start"
                dominantBaseline="hanging"
                lengthAdjust="spacingAndGlyphs" 
                transform={"translate("+x+" "+y+") rotate("+angle+")"}
                textRendering="optimize-speed"
                y="2px"
            >
                <tspan textRendering="optimize-speed">{label}</tspan>
            </text>
        ) : (null)
    }

    render() {
        const bounds = this.props.labelGroup.getBounds();

        if(!bounds.isFinite())
            return null;

        const size = bounds ? bounds.size() : {width: 0, height: 0};
        const center = bounds.center();

        const longestSize = Math.max(size.width, size.height);

        // Temporarily forcing this to label from bottom-left
        //const isTallerThanWide = bounds.isTallerThanWide();
        //const isTallerThanWide = true;
        /*
        const {x, y} = {
            x: bounds ? bounds.xMin : 0, 
            y: bounds ? isTallerThanWide ? bounds.yMax : bounds.yMin : 0};
        */
        //const angle = isTallerThanWide ? -90 : 0;
        
        // Grab the first fixture in the group, and use its angle to set the text rotation
        const fixture = this.props.labelGroup.fixtures[0].fixtureData;
        const angle = fixture.ROTATION_INT * -1;

        // FIXME: lots of hard coded constants
        const minSize = 2;
        const maxSize = 30;
        const maxContainerSize = 500;

        const textLength = this.props.labelGroup.label ? this.props.labelGroup.label.length : 0;
        
        //const containerSize = isFinite(size.height) && isNumber(size.height) ? Math.min(maxContainerSize, size.height) : 0;
        const containerSize = isFinite(longestSize) && isNumber(longestSize) ? Math.min(maxContainerSize, longestSize) : 0;
        const ratio = (containerSize / 10) / (textLength * 2);  //5
        const finalSize = maxSize * ratio;
        const fontSize = isNaN(finalSize) ? 0 : Math.max(Math.min(finalSize, maxSize), minSize);

        // Don't render labels if smallest dimension is < 12 inches
        const cull = size.width < 12 || size.height < 12 || fixture.WIDTH < 12 || fixture.DEPTH < 12;

        return this.props.debug ? this.renderLabelGroupDebug(center.x, center.y, angle, fontSize, this.props.labelGroup.label, center.x - size.width * 0.5 , center.y - size.height * 0.5, size, cull)
            : this.renderLabelGroup(center.x, center.y, angle, fontSize, this.props.labelGroup.label, center.x - size.width * 0.5, center.y - size.height * 0.5, bounds, cull);
    }

    shouldComponentUpdate() {
        return true;
    }
}