import Vector2D from './vector-2d.js';

export default class Bounds
{
    // FIXME: should remove extra values from the constructor.  Bounds define a AABB, not orientation or geometry
    constructor(xMin, xMax, yMin, yMax,fos_originvalue1,fos_originvalue2,fos_rightvalue1,fos_rightvalue2,fosGeomStr)
    {
        this.xMin = xMin === undefined ? Infinity : xMin;
        this.yMin = yMin === undefined ? Infinity : yMin;
        this.xMax = xMax === undefined ? -Infinity : xMax;
        this.yMax = yMax === undefined ? -Infinity : yMax;
        this.fos_originvalue1 = fos_originvalue1 === undefined ? 0 : fos_originvalue1;
        this.fos_originvalue2 = fos_originvalue2 === undefined ? 0 : fos_originvalue2;
        this.fos_rightvalue1 = fos_rightvalue1 === undefined ? 0 : fos_rightvalue1;
        this.fos_rightvalue2 = fos_rightvalue2 === undefined ? 0 : fos_rightvalue2;

        let createPoint = (x) => {
            let coords = x.split(" ");
            return {x: coords[0], y: coords[1]};  // Client wants the data flipped
        };
        this.fosPoints = fosGeomStr === undefined ? null : fosGeomStr.replace("MULTILINESTRING((", "").replace("))", "").split(",").map(createPoint);
    }

    /**
    * Expand bounds
    * @param {*} bounds Bounds object to expand with
    */
    expand(bounds) {
        
        if(bounds) {
            const mins = { x: Math.min(bounds.xMin, this.xMin), y: Math.min(bounds.yMin, this.yMin) };
            const maxs = { x: Math.max(bounds.xMax, this.xMax), y: Math.max(bounds.yMax, this.yMax) };
            return new Bounds(mins.x, maxs.x, mins.y, maxs.y);
        } else {
            return new Bounds(this.xMin, this.xMax, this.yMin, this.yMax);
        }
    }

    /**
    * Size of the bounds
    */
    size() {

        return {
            width: this.xMax - this.xMin,
            height: this.yMax - this.yMin
        }
    }

    /**
    * Center point/origin of the bounds
    */
    center() {

        return new Vector2D(( this.xMax - this.xMin ) / 2 + this.xMin, (this.yMax - this.yMin ) / 2 + this.yMin);
    }

    /**
    * Distance between two bounds (from their origins)
    */
    distance(bounds) {

        const center = this.center();
        const otherCenter = bounds.center();

        return center.distance(otherCenter);
    }

    /**
    * Distance between two bounds (from their edges)
    */
    distanceFromEdge(bounds) {

        const center = this.center();
        const otherCenter = bounds.center();

        const size = this.size();
        const otherSize = bounds.size();

        const toOther = otherCenter.subtract(center).normalize();
        const toCenter = center.subtract(otherCenter).normalize();

        const edgePoint = toOther.mapToRectangle(size.width, size.height).add(center);
        const otherEdgePoint = toCenter.mapToRectangle(otherSize.width, otherSize.height).add(otherCenter);

        return Math.min(edgePoint.distance(otherEdgePoint), center.distance(otherCenter));
    }

    /**
    * Return true if height is greater than width
    */
    isTallerThanWide() {

        const size = this.size();
        return size.height > size.width;
    }

    /**
    * Check if bounds are defined
    */
    isFinite() {
        return isFinite(this.xMin) && 
            isFinite(this.xMax) && 
            isFinite(this.yMin) && 
            isFinite(this.yMax);
    }

    /**
    * Expand bounds on all edges by input value
    */
    pad(xPad, yPad) {
        return new Bounds(this.xMin - xPad, this.xMax + xPad, this.yMin - yPad, this.yMax + yPad);
    }

    /**
    * Pad shortest dimension so that the ratio of 
    * the resulting bounds matches the input
    */
    padToRatio(ratio) {

        const taller = this.isTallerThanWide();
        const {width: w, height: h} = this.size();

        // Pad width if bounds are tall and ratio is wide
        if(taller && ratio < 1) {
            // Pad width
            const newWidth = h / ratio;
            const hPad = (newWidth - w) * 0.5
            return this.pad(hPad, 0);
        }

        // Pad height if bounds are wide and ratio is wide
        if((!taller && ratio < 1)) {
            // Pad height
            const newHeight = ratio * w;
            const vPad = (newHeight - h) * 0.5;
            return this.pad(0, vPad);
        }

        // Pad width if bounds are tall and ratio is tall
        if(taller && ratio > 1) {
            // Pad width
            const newWidth = h / ratio;
            const hPad = (newWidth - w) * 0.25;  // 0.25 is just a magic number I guess; the pan/zoom lib is unpredictable
            return this.pad(hPad, 0);
        }

        // Pad height if bounds are wide and ratio is tall
        if((!taller && ratio > 1)) {
            // Pad height
            const newHeight = ratio * w;
            const vPad = (newHeight - h);
            return this.pad(0, vPad);
        }

        return this;
    }
}