import React from 'react';
import Iframe from 'react-iframe';

import './rackInfo.scss';

export default class RackInfo extends React.Component {

    // Props: url, onClose, onMouseUp(hack to prevent hammer.js from not releasing pan)

    render() {
        // OPTIMIZE: This is causing occasional reflow / layout thrashing when a fixture is clicked
        // Removed non functional button.
        // <button type="button" className="close" onClick={this.props.onRefresh}>Refresh</button>
        const {url, modalChild} = this.props;
        return (
            <div className="modal">
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.onClose}>Close</button>
                </div>
                {(url.length > 0 && modalChild === undefined) &&
                <Iframe name="taskIframe" id="taskIframe" className="modal-iframe" title="rackinfo" scrolling="yes" frameBorder="0"
                    url={this.props.url} onClick={this.props.onClose}
                    >
                </Iframe>
                }
                {modalChild !== undefined &&
                    <div className="modal-content">{modalChild}</div>
                }
            </div>
        )
    }
}