import React from 'react';
import './app.css';
import ErrorBoundary from '../error-boundary/error-boundary';
import SVGMapContainer from '../svg-map/svg-map-container';

const API_URL = 'https://app3.retailgis.com/store_map/smartmap_mobile/main.htx';
const RACK_INFO_URL = 'https://app3.retailgis.com/store_map/map_fixture_detail.htx';
const DEFAULT_MODAL_URL = 'https://www.retailgis.com/store_map/welcome.cfm';
//const DEFAULT_MODAL_URL = 'testWelcomePanel.html';  // For testing cross site script calls
const STORE_MAP_URL = 'https://app3.retailgis.com/store_map/fmap/images';
const MULTI_RACK_INFO_URL = 'https://app3.retailgis.com/store_map/admin/map_admin_main.htx';
const MARKER_INFO_URL = 'https://app3.retailgis.com/store_map/x_smartmap_overview_photos_detail.htx';


function GetInputParams() {
  const urlString = window.location.href;
  return new URL(urlString);
}

function GetParams() {
  const appUrl = GetInputParams();
  const urlParams = appUrl.searchParams;
  const paramKeys = ['retailer_id_no', 'division_id_no', 'store_no', 'layers', 'user', 'fill_color', 'line_color', 'label_field', 'group_distance', 'zoom', 'pan_x', 'pan_y', 'auto_focus', 'debug', 'test_data', 'theme']
  const params = {
    retailerId: urlParams.get("retailer_id_no") || 60000,
    divisionId: urlParams.get("division_id_no") || 60000,
    store: urlParams.get("store_no") || -1,
    layers: urlParams.get("layers") || 'RACKS',
    user: urlParams.get("user") || 'DLUSER@RETAILGIS.COM',
    fillColor: urlParams.get("fill_color") || 'orange',
    lineColor: urlParams.get("line_color") || 'black',
    labelField: urlParams.get("label_field") || 'PRODUCT_DESC',
    groupDistance: urlParams.get("group_distance") || 110,
    zoom: urlParams.get("zoom") || 1,
    panX: urlParams.get("pan_x") || 0,
    panY: urlParams.get("pan_y") || 0,
    autoFocus: urlParams.get("auto_focus") || false,
    debug: urlParams.get("debug") || false,
    testData: urlParams.get("test_data") || false,
    theme: urlParams.get("theme") || '0',
  }
  let result = {};
  let extraParams = "";
  const searchParams = Object.fromEntries(new URLSearchParams(appUrl.search));
  for (let key in searchParams) {
    if (paramKeys.includes(key) === false) {
      extraParams = extraParams + `&${key}=${searchParams[key]}`
      result[key] = searchParams[key];
    }
  }
  console.log(params, extraParams);
  return { params, extraParams };
}

function App() {
  const rtnParams = GetParams();
  return (
    <div className='App'>
      <ErrorBoundary>
        <SVGMapContainer apiUrl={API_URL}
                rackInfoUrl={RACK_INFO_URL}
                defaultModalUrl={DEFAULT_MODAL_URL}
                storeMapUrl={STORE_MAP_URL}
                windowWidth={window.innerWidth}
                windowHeight={window.innerHeight}
                inputParams={rtnParams.params}
                inputParamsString={GetInputParams().search}
                multiRackInfoUrl={MULTI_RACK_INFO_URL}
                inputExtraParams={rtnParams.extraParams}
                markerInfoUrl={MARKER_INFO_URL}
                >
        </SVGMapContainer>
      </ErrorBoundary>
    </div>
  );
}

export default App;
