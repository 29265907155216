import React from 'react';
import marker_png from './env.png'

export default class Marker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const clickMarkerEvent = (event) => {
            this.props.onClick(this.props.marker);
            event.stopPropagation();
        }
        return this.props.markerId === '' ? null :
            (<image className={"marker"} key={this.props.markerId}
                id={this.props.markerId}
                href={marker_png}
                x={this.props.markerX - 21}
                y={-1 * this.props.markerY - 42} style={{ height: 42, width: 42 }}
                onClick={clickMarkerEvent}
                onTouchEnd={clickMarkerEvent}
            />)
    }
    shouldComponentUpdate(nextProps) {
        return null;
    }
    componentDidUpdate() {
        try {

        } catch (err) {
            console.log(`Couldn't update Marker id ${this.props.marker.ID}: `, err);
        }
    }
}