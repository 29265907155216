import PointGeometry from '../models/point-geometry.js';
import { Point, Rect } from '@svgdotjs/svg.js';
export default class MapUtils {
    constructor(svg,transformed,fixtureData) {
        this.svg =svg;
        this.transformed = transformed;
        this.fixtureData = fixtureData;        
    }
    // translate page to SVG coordinate
    getSvgPoint(x, y) {
        const pt = this.svg.createSVGPoint();
        pt.x = x;
        pt.y = y;
        return pt.matrixTransform(this.transformed.getScreenCTM().inverse());
    }
    getBottomLeftVerttex(points) {
        let btmLeftX = 0;
        let btmLeftY = 0;
        for (let i = 0; i < points.length; i++) {
            let ptX = points[i].x;
            let ptY = points[i].y;
            //console.log("point x y ", ptX, ptY);
            if (btmLeftX === 0 && btmLeftY === 0) {
                btmLeftX = ptX;
                btmLeftY = ptY;
            } else {
                if (ptY - btmLeftY >= 2) //two inches Delta
                {
                    btmLeftX = ptX;
                    btmLeftY = ptY;
                }
                else if (Math.abs(ptY - btmLeftY) < 2) //two inches Delta
                {
                    if (ptX < btmLeftX) {
                        btmLeftX = ptX;
                        btmLeftY = ptY;
                    }
                }
            }
        }
        return {
            x: btmLeftX,
            y: -1 * btmLeftY
        };
    }
    async getSnapPoint(mX, mY, snapRadius = 18) {
        let mPoint = new Point(mX, mY);
        let pti = new Point();
        let minDist = 0;
        let iDist;
        let snapPoint = null;
        let destRACKID = 0;
        this.fixtureData.forEach((f) => {
            let fixPolygon = this.svg.getElementById(f.RACK_ID);
            if (fixPolygon) {
                pti = this.getClosestVertex({ x: mX, y: -1 * mY }, fixPolygon.points);
                if (this.getDistance(pti, mPoint) < snapRadius * 3) {
                    iDist = this.getDistance(pti, mPoint);
                    if (snapPoint === null || iDist < minDist) {
                        minDist = iDist;
                        snapPoint = pti;
                        destRACKID = f.RACK_ID;
                    }
                }
            }
        })
        if (minDist < snapRadius) //1.5 feet
            return { snapPoint, destRACKID };
        else
            return null;
    }
    getAngleFromPoint(point, centerPoint) {
        let dy = (point.y - centerPoint.y),
            dx = (point.x - centerPoint.x);
        let theta = Math.atan2(dy, dx);
        let angle = (((theta * 180) / Math.PI)) % 360;
        angle = (angle < 0) ? 360 + angle : angle;
        return Math.round(angle);
    }
    getDistance(point1, point2) {
        //console.log("getDistance --------------",point1, point2,point1.x, point1.y,point2.x, point2.y);
        let xs = 0;
        let ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.round(Math.sqrt(xs + ys));
    }
    getClosestVertex(mPoint, dbPoints) {
        let closeVertexPt = new Point();
        if (dbPoints.length >= 0) {
            closeVertexPt = new Point(dbPoints[0].x, dbPoints[0].y);
            let minDist = this.getDistance(mPoint, closeVertexPt);
            for (let i = 0; i < dbPoints.length; i++) {
                let dbPnt = new Point(dbPoints[i].x, dbPoints[i].y);
                let iDist = this.getDistance(mPoint, dbPnt);
                if (minDist < 0 || minDist > iDist) {
                    closeVertexPt = dbPnt;
                    minDist = iDist;
                }
            }
        }
        //return closeVertexPt;
        return {
            x: closeVertexPt.x,
            y: -1 * closeVertexPt.y
        };
    }
    getGeometryFromPoints(dbPoints) {
        let outGeoStr = "POLYGON((";
        let str = "";
        for (let i = 0; i < dbPoints.length; i++) {
            let pt = { x: dbPoints[i].x, y: dbPoints[i].y }
            outGeoStr += str + pt.x + " " + pt.y
            str = ","
        }
        outGeoStr += "))";
        return outGeoStr;
    }
    getPointsFromGeometry(geometryString) {
        let createPoint = (x) => {
            let coords = x.split(" ");
            return { x: coords[0], y: coords[1] * -1 };  // Client wants the data flipped
        };
        let points = geometryString.replace("POLYGON((", "").replace("))", "").split(",").map(createPoint);
        //points.pop();  // Last point in the data is redundant

        return new PointGeometry(points);
    }
    getPointsFromMultyGeometry(multyGeometryString) {
        let createPoint = (x) => {
            let coords = x.split(" ");
            return { x: coords[0], y: coords[1] }; //this.getSvgPoint(coords[0], coords[1]);  // Client wants the data flipped
        };
        const pointsArray = multyGeometryString.replace("MULTIPOLYGON(", "").replace(")))", "))").replaceAll(")),((", "~").replace("((", "").replace("))", "")
            .split("~").map(function (data, index) {
                let pointGeometry =  new PointGeometry(data.split(",").map(createPoint));
                //console.log("points direct--------------->",data.split(",").map(createPoint));
                return {pointGeometry,originalPoints:data.split(",").map(createPoint)};//new PointGeometry(points);
            });
        return pointsArray;
    }
    displaceDbPoints(displacementPnt, arrayGeomsPnts) {
        //let svgPos = this.svg.getBoundingClientRect();
        //let deltaFixX = 0
        let deltaFixY = 0
        let preFixStartX = 0, preFixStartY = 0
        let allPnts = []
        let arrGeomsDisplacePnts = arrayGeomsPnts.map((arrayGeomPnts, index) => {
            let points = arrayGeomPnts.pointGeometry.points
            const bounds = arrayGeomPnts.pointGeometry.getBounds();
            const origin = bounds.center();
            const size = bounds.size();
            let originalPnts = arrayGeomPnts.originalPoints
            //console.log("Fix start differ ==== ", Math.abs(Number(preFixStartX))," > ",Math.abs(Number(originalPnts[0].x)));
            //deltaFixX = (Math.abs(Number(preFixStartX)) > Math.abs(Number(originalPnts[0].x))) ? (Math.abs(Number(preFixStartX)) - Math.abs(Number(originalPnts[0].x))) : 0
            //deltaFixY = (Math.abs(Number(preFixStartY)) > Math.abs(Number(originalPnts[0].y))) ? (Math.abs(Number(preFixStartY)) - Math.abs(Number(originalPnts[0].y))) : 0
            if (preFixStartX === 0 && preFixStartY === 0) {
                preFixStartX = Number(originalPnts[0].x)
                preFixStartY = Number(originalPnts[0].y)
            } else {
                //deltaFixX = (Number(preFixStartX) - Number(originalPnts[0].x))
                deltaFixY = (Number(preFixStartY) - Number(originalPnts[0].y))
                preFixStartX = Number(originalPnts[0].x)
                preFixStartY = Number(originalPnts[0].y)                
            }
            let disPnts = []
            let disPnts1 = []
            let insertPoint = { x: 0, y: 0 }
            for (let i = 0; i < points.length; i++) {
                disPnts1.push({ x: (Number(points[i].x) + Number(displacementPnt.x)), y: (Number(points[i].y) + Number(displacementPnt.y)) }) //this.getSvgPoint(Number(points[i].x) , Number(points[i].y))
                //let deltaX = Math.abs(Number(points[0].x) - Number(points[i].x))
                //let deltaY = Math.abs(Number(points[0].y) - Number(points[i].y))                
                points[i].x = Number(points[i].x) + Number(displacementPnt.x);
                points[i].y = Number(points[i].y) + Number(displacementPnt.y);
            }
            //console.log(`displaceDbPoints before --> ${index}`, points, displacementPnt, svgPos, originalPnts, bounds, origin, size);
            for (let i = 0; i < points.length; i++) {
                if (i === 0) {
                    //disPnts.push({ x: (Number(points[i].x)), y: (-1 * (Number(points[i].y) - Number(originalPnts[i].y) + Number(deltaFixY))) })
                    disPnts.push({ x: (Number(points[i].x)), y: -1 * (Number(displacementPnt.y) + Number(deltaFixY)) })
                    allPnts.push({ x: (Number(points[i].x)), y: -1 * (Number(displacementPnt.y) + Number(deltaFixY)) })
                } else if (i !== 0) {
                    //let deltaX = Number(originalPnts[0].x) - Number(originalPnts[i].x)
                    let deltaY = Number(originalPnts[0].y) - Number(originalPnts[i].y)
                    //deltaY = (originalPnts[0].y < 0) ? (-1 * deltaY) : deltaY
                    //disPnts.push({ x: (Number(points[i].x)), y: (-1 * (Number(points[i].y) - Number(originalPnts[i].y) + Number(deltaY) + Number(deltaFixY))) })
                    disPnts.push({ x: (Number(points[i].x)), y: -1 * (Number(displacementPnt.y) + Number(deltaFixY) + Number(deltaY)) })
                    allPnts.push({ x: (Number(points[i].x)), y: -1 * (Number(displacementPnt.y) + Number(deltaFixY) + Number(deltaY)) })
                    insertPoint.x += (Number(points[i].x));//(Number(displacementPnt.x) + Number(deltaFixX) + Number(deltaX));
                    insertPoint.y += -1 * (Number(displacementPnt.y) + Number(deltaFixY) + Number(deltaY));//(-1 * (Number(points[i].y) - Number(originalPnts[i].y) + Number(deltaY) + Number(deltaFixY)));
                    //console.log(`************************ --> ${i + 1}`,Number(deltaFixX),Number(deltaFixY),"  ----  ",Number(originalPnts[0].x), " - ", Number(originalPnts[i].x), " = ", deltaX, " ----- ",Number(originalPnts[0].y), " - ", Number(originalPnts[i].y), " = ", deltaY);
                }
                //console.log(`>>>>>>>>>>>>>>>>>>>>> --> ${index}`, Number(points[i].x) - Number(originalPnts[i].x),Number(points[i].y) - Number(originalPnts[i].y));
            }
            insertPoint.x /= (points.length - 1);
            insertPoint.y /= (points.length - 1);
            //console.log(`displaceDbPoints after--> ${index}`, disPnts, insertPoint,disPnts1, displacementPnt, svgPos);
            return { points: disPnts, insertPoint, size, origin };
        });
        return { arrGeomsDisplacePnts: arrGeomsDisplacePnts, allPnts};
    }
    getPolygonMBR(pntsArray) {
        let mbr;
        let len = pntsArray.length;
        for (let i = 0; i < len; i++) {
            let pntObj = new Point(pntsArray[i].x, pntsArray[i].y);//{ x: pntsArray[i].x, y: pntsArray[i].y };
            console.log(`pntObj ${i}`,pntObj);
            if (pntObj) {
                if (!mbr)
                    mbr = new Rect(pntObj.x, pntObj.y, 0, 0);
                else {
                    let mbr2 = new Rect(pntObj.x, pntObj.y, 0, 0);
                    let mbrold = new Rect(mbr.x, mbr.y, mbr.width, mbr.height);
                    mbr.x = Math.min(mbr2.x, mbrold.x);
                    mbr.y = Math.min(mbr2.y, mbrold.y);
                    mbr.width = Math.max(mbr2.x + mbr2.width, mbrold.x + mbrold.width) - mbr.x;
                    mbr.height = Math.max(mbr2.y + mbr2.height, mbrold.y + mbrold.height) - mbr.y;
                }
            }
        }
        return mbr;
    }
    angle(originX, originY, targetX, targetY) {
        var dx = originX - targetX;
        var dy = originY - targetY;
        
        // var theta = Math.atan2(dy, dx);  // [0, ?] then [-?, 0]; clockwise; 0� = west
        // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; clockwise; 0� = west
        // if (theta < 0) theta += 360;     // [0, 360]; clockwise; 0� = west
        
        // var theta = Math.atan2(-dy, dx); // [0, ?] then [-?, 0]; anticlockwise; 0� = west
        // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; anticlockwise; 0� = west
        // if (theta < 0) theta += 360;     // [0, 360]; anticlockwise; 0� = west
        
        // var theta = Math.atan2(dy, -dx); // [0, ?] then [-?, 0]; anticlockwise; 0� = east
        // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; anticlockwise; 0� = east
        // if (theta < 0) theta += 360;     // [0, 360]; anticlockwise; 0� = east
        
        var theta = Math.atan2(-dy, -dx); // [0, ?] then [-?, 0]; clockwise; 0� = east
        theta *= 180 / Math.PI;           // [0, 180] then [-180, 0]; clockwise; 0� = east
        if (theta < 0) theta += 360;      // [0, 360]; clockwise; 0� = east
        
        return theta;
    }
    screenToSVG(screenX, screenY) {
        var p = this.svg.createSVGPoint()
         p.x = screenX
         p.y = screenY
         return p.matrixTransform(this.svg.getScreenCTM().inverse());
     }
     
     SVGToScreen(svgX, svgY) {
        var p = this.svg.createSVGPoint()
         p.x = svgX
         p.y = svgY
         return p.matrixTransform(this.svg.getScreenCTM());
     }
}