import PointGeometry from './point-geometry.js';

/**
 * Class for holding processed fixture
 */
class Fixture
{
    constructor(fixtureData, geometry, origin, bounds, isEndCap) {

        this.fixtureData = fixtureData;
        this.geometry = Array.isArray(geometry) ? geometry : [geometry];
        this.origin = origin;
        this.bounds = bounds;
        this.isEndCap = isEndCap;
    }

    hasPointGeometry() {
        return Array.isArray(this.geometry[0].points) && this.geometry[0].points.length > 1;
    }

    hasDimensions() {
        return this.fixtureData.WIDTH > 0 && this.fixtureData.DEPTH > 0;
    }

    // Returns an array
    getPolyString() {
        return this.geometry.map(geo => {
            const points = geo.points;
            let polygonString = '';
            if(points) {
                points.forEach(p => {
                    const x = p.x;
                    const y = p.y;
                    polygonString = polygonString + `${x},${y} `;
                });
                return polygonString.trimEnd();
            }
    
            return '';
        });
    }

    // Find the center of the geometry group 
    // and translate all points so that the center is located at 0,0.  
    // This really shouldn't be necessary; the points that define a fixture
    // should be in local space to begin with...
    getGeometryCenteredToOrigin() {
        const center = this.bounds.center();

        // Return a new array of point geometry(s)
        return this.geometry.map(geo => {
            return new PointGeometry(geo.points.map(point => ({x: point.x - center.x, y: point.y - center.y})));
        });
    }

    // Find the bottom left of the geometry group 
    // and translate all points so that the center 
    // is located there.
    getGeometryCenteredToBottomLeft() {
        const center = { x: this.bounds.xMin, y: this.bounds.yMax };

        // Return a new array of point geometry(s)
        return this.geometry.map(geo => {
            return new PointGeometry(geo.points.map(point => ({x: point.x - center.x, y: point.y - center.y})));
        });
    }

    draw(canvas, drawInfo) {

        if(this.bounds) {

            // Draw geometry points.  Build a string to pass to canvas.polygon.
            const polygonString = this.getPolyString()[0];

            let rackColor = (this.fixtureData.BAYLOCK_COLOR === undefined || this.fixtureData.BAYLOCK_COLOR === "0") ? drawInfo.fillColor : "#" + this.fixtureData.BAYLOCK_COLOR;

            if(this.isEndCap && drawInfo.debug)
                rackColor = "#ffffff";

            // Draw polygon
            canvas.polygon(polygonString).fill(rackColor).stroke({ width: 2, color: drawInfo.lineColor }).opacity(1.0);
            
            // Draw origin
            if(drawInfo.debug)
                canvas.rect(2, 2).fill('black').move(this.origin.x, this.origin.y);
        }
    }
}

export default Fixture;