import Bounds from '../util/bounds.js';

export default class PointGeometry {

    constructor(points) {

        this.points = points;
    }

    getBounds() {

        if(this.points) {

            const mins = this.points.reduce((accumulator, point) => { return { x: Math.min(accumulator.x, point.x), y: Math.min(accumulator.y, point.y) } });
            const maxs = this.points.reduce((accumulator, point) => { return { x: Math.max(accumulator.x, point.x), y: Math.max(accumulator.y, point.y) } });
            return new Bounds(mins.x, maxs.x, mins.y, maxs.y);

        } else {

            // No geometry points... calculate from center point and width / depth?
            return new Bounds(0, 0, 0, 0);
        }
    }

    combine(points) {
        if(Array.isArray(points)) {
            const newPoints = [...this.points, ...points];
            return new PointGeometry(newPoints);
        }

        return points;
    }

    isEmpty() {
        const hasOnlyOnePoint = this.points.length <= 1;
        return hasOnlyOnePoint;
    }
}