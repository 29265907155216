import PointGeometry from './point-geometry.js';

/**
 * Class for processing raw fixture json data recieved from the RetailGIS API.
 * Used to construct a Fixture object.
 */
export default class FixtureData {

    constructor(fixtureJson) {

        this.fixtureJson = fixtureJson;
    }

    /**
    * Creates an array of coordinate objects from the RACKGEOMETRY of a fixture: [{x, y}, {x, y}, ...]
    * RACKGEOMETRY string example: "POLYGON((17081.231484525 1826.090315248,17081.231484525 1850.090315248,17057.231484525 1850.090315248,17057.231484525 1826.090315248,17081.231484525 1826.090315248))"
    */
    getPointGeometry() {
        
        try {

            let geometryString = this.fixtureJson.RACKGEOMETRY;

            let createPoint = (x) => {
                let coords = x.split(" ");
                return {x: coords[0], y: coords[1] * -1};  // Client wants the data flipped
            };

            let points = geometryString.replace("POLYGON((", "").replace("))", "").split(",").map(createPoint);
            points.pop();  // Last point in the data is redundant

            return new PointGeometry(points);

        } catch(error) {
            // There are no defined points
            return new PointGeometry([{x: 0, y: 0}]);
            //return new PointGeometry([{x: this.fixtureJson.CENTER_X, y: this.fixtureJson.CENTER_Y}]);
        }
    }

    // Notes: 
    // Takes an array of PointGeometry, returns single 
    // pointGeometry that includes all points
    combinePolygons(polygons) {
        return polygons.reduce((acc, curr) => {
            return curr.combine(acc);
        });
    }

    // Notes:
    // The fixture list API has different specs that include multiple polygons.  
    // The name of the field for the list of geometry points is different from the fixture API.
    getMultiPolyPointGeometry() {
        try {

            const geometryString = this.fixtureJson.FIXTUREGEOMETRY || "";

            const createPoint = (x) => {
                let coords = x.split(" ");
                return {x: Number(coords[0]), y: Number(coords[1]) * -1};  // Client wants the data flipped
            };

            // Should give us a list of polygons: '((3 3, 2 2)),((3 3, 1 1)),...'
            const polygonStrings = geometryString.replace("MULTIPOLYGON(", "").replace(")))", "))");
            const polygonStringsSplit = polygonStrings.replaceAll(")),", "))|").split("|");

            const polygons = polygonStringsSplit.map(polyString => {
                const points = polyString.replace("((", "").replace("))", "").split(",").map(createPoint);
                return points.length > 0 ? new PointGeometry(points) : new PointGeometry([{x: 0, y: 0}]);
            });

            if(polygons.length > 0)
                return polygons;  //[this.combinePolygons(polygons)];

            return [new PointGeometry([{x: 0, y: 0}])];

        } catch(error) {
            // There are no defined points
            console.log('Error building fixture multi-geometry: ', error);
            return [new PointGeometry([{x: 0, y: 0}])];
        }
    }

    getIsEndCap() {
        return this.fixtureJson.OBJTYPE === "E" || this.fixtureJson.OBJTYPE === "S";
    }
}