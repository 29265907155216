import React from 'react';
import Fixture from '../fixture/fixture';
import LabelGroup from '../label-group/label-group';
import Marker from '../marker/marker';
import fos_png from './fos.png'
import fosL_png from './fosL.png'
import fosR_png from './fosR.png'
import fosB_png from './fosB.png'

export default class SVGMap extends React.Component {

    // Props: storeData, storeExtents, fillColor, lineColor, storeMapImage, onClickFixture, renderLabelGroups, selectedFixture, onClickFixture,
    // onEnterFixture, onLeaveFixture, onUpdated

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderFixture(fixture, rackColor, lineColor, onClick, onEnter, onLeave, fixtureIndex, selected, currentAction) {
        return (<Fixture fixture={fixture} rackColor={rackColor} lineColor={lineColor} onClick={onClick} onEnter={onEnter} onLeave={onLeave} key={fixtureIndex} selected={selected} currentAction={currentAction}></Fixture>)
    }

    renderLabelGroup(labelGroup, labelGroupIndex) {
        return (<LabelGroup labelGroup={labelGroup} index={labelGroupIndex} key={labelGroupIndex} debug={this.props.debug}></LabelGroup>)
    }
    renderMarker(marker, markerId, markerX, markerY, onClick, markerIndex, currentAction) {
        return (<Marker marker={marker} markerId={markerId} markerX={markerX} markerY={markerY} onClick={onClick} key={markerId} currentAction={currentAction}></Marker>)
    }
    render() {       
        const {xMin, xMax, yMin, yMax, fos_originvalue1, fos_originvalue2,fos_rightvalue1,fos_rightvalue2} = this.props.storeExtents;
        const mapOrigin = {x: xMin, y: -1 * yMax}; // -1 * yMax to correctly position to flipped fixture data.  FIXME: need to pass in a transform parameter
        const mapSize = {width: xMax - xMin, height: yMax - yMin};
        //console.log("fos_rightvalue1,fos_rightvalue2------------",fos_rightvalue1,fos_rightvalue2);
        let fosImg = fos_png;
        if(fos_rightvalue1 === 0 && fos_rightvalue2 === -1)
            fosImg = fosR_png;
        else if(fos_rightvalue1 === 0 && fos_rightvalue2 === 1)
            fosImg = fosL_png; 
        else if(fos_rightvalue1 === -1 && fos_rightvalue2 === 0)
            fosImg = fosB_png;                        
        // SVG viewer requires that the coordinates are positive
        const xTransform = -1 * this.props.fixtureBounds.xMin;
        const yTransform = -1 * this.props.fixtureBounds.yMin;
        return (
            <g id="transformed"
                transform={"translate("+xTransform+","+yTransform+")"}
            >
                <image href={this.props.storeMapImage} x={mapOrigin.x} y ={mapOrigin.y} width={mapSize.width} height={mapSize.height}/>
                {
                    this.props.storeData.markersData.map((marker, index) => {
                        return this.renderMarker(marker,marker.ID,marker.x,marker.y,
                            this.props.onClickMarker,
                            index,
                            this.props.currentAction);
                    })
                }                
                {
                    this.props.storeData.fixtures.map((fixture, index) => {
                        return this.renderFixture(fixture, 
                            fixture.fixtureData.BAYLOCK_COLOR ? fixture.fixtureData.BAYLOCK_COLOR : this.props.fillColor, 
                            this.props.lineColor, 
                            this.props.onClickFixture,
                            this.props.onEnterFixture,
                            this.props.onLeaveFixture,
                            index, 
                            fixture === this.props.selectedFixture,
                            this.props.currentAction);
                    })
                }
                <image href={fosImg} x={fos_originvalue1} y={fos_originvalue2*-1} style={{ height: 200, width: 150}} alt="Front Of The Store"/>
                {this.props.renderLabelGroups ? this.props.storeData.labelGroups.map((labelGroup, index) => this.renderLabelGroup(labelGroup, index) ) : ""}
            </g>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.renderLabelGroups !== this.props.renderLabelGroups ||
            nextProps.selectedFixture !== this.props.selectedFixture ||
            nextProps.storeData !== this.props.storeData;
    }

    // Sometimes the container needs to be notified when the map has changed
    componentDidMount() {
        
        if(this.props.onUpdated)
            this.props.onUpdated();
    }
}